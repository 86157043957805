import { ADMIN, CHILDREN } from "./api.routes";
import { deleteRequest, getRequest, postMultiPart } from "./http";
import { IPaginatedChildrenTable } from "../common/interfaces/children.interface";
import { CreateOrUpdateChildDto } from "../common/dtos/children.dto";

export function getAllChildren(
  queryParams?: string
): Promise<IPaginatedChildrenTable> {
  return getRequest(`${ADMIN}/${CHILDREN}${queryParams ? queryParams : ""}`);
}

export function getChildrenDetails(childId: number): Promise<any> {
  return getRequest(`${ADMIN}/${CHILDREN}/${childId}`);
}

export function deleteChild(childId: number): Promise<void> {
  return deleteRequest(`${ADMIN}/${CHILDREN}/${childId}`);
}

export function createOrUpdateChildren(data: CreateOrUpdateChildDto) {
  const body = new FormData();
  body.append("childInfo", JSON.stringify(data.childInfo));
  if (data.logo) body.append("avatar", data.logo);
  return postMultiPart(`${ADMIN}/${CHILDREN}`, body);
}
