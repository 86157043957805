import { useEffect } from "react";
import { useNavigate } from "react-router";
import { OVERVIEW } from "../../common/constants/routes.constants";

function PageNotFound() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(OVERVIEW);
  }, []);

  return (
    <div className="login-wrapper">
      <div className="form-section" style={{ width: "100%" }}>
        <h2>Page not found</h2>
      </div>
    </div>
  );
}

export default PageNotFound;
