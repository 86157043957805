import { DataGrid, GridColDef } from "@mui/x-data-grid";
import InitialsArray from "../../components/Tables/InitialsArray";
import SettingsMenu from "../../components/SettingsMenu/SettingsMenu";
import TitleArea from "../../components/Layout/TitleArea/TitleArea";
import SearchBar from "../../components/SearchBar/SearchBar";
import ImageArray from "../../components/Tables/ImageArray";
import GroupColorCell from "../../components/Tables/GroupColorCell";
import { useNavigate } from "react-router";
import { GROUP_DETAILS } from "../../common/constants/routes.constants";
import { useEffect, useState } from "react";
import { getAllGroups } from "../../api/groups.api";
import {
  IGroupsTableChild,
  IGroupsTableTeacher,
  ITableGroup,
} from "../../common/interfaces/group.interfaces";
import Loader from "../../components/Loader/Loader";
import { mapHexToColor, toggleSort } from "../../common/utils/helpers";
import { ITEMS_PER_PAGE } from "../../common/constants/common.constants";

export const columns: GridColDef[] = [
  {
    field: "adminGroupName",
    headerName: "GROUP NAME",
    headerClassName: "table-header",
    flex: 10,
  },
  {
    field: "color",
    headerName: "COLOR",
    headerClassName: "table-header",
    flex: 10,
    sortable: false,
    renderCell: (params) => {
      return (
        <GroupColorCell
          hex
          color={mapHexToColor(params.row.adminGroupHexColor)}
        />
      );
    },
  },
  {
    field: "teachers",
    headerName: "TEACHERS",
    headerClassName: "table-header",
    sortable: false,
    flex: 10,
    renderCell: (params) => {
      return (
        <InitialsArray
          fullnames={params.row.teachers.map(
            (teacher: IGroupsTableTeacher) => teacher.teacherFullName
          )}
        />
      );
    },
  },
  {
    field: "children",
    headerName: "CHILDREN",
    headerClassName: "table-header",
    sortable: false,
    flex: 10,
    renderCell: (params) => {
      return (
        <ImageArray
          images={params.row.children.map(
            (item: IGroupsTableChild) => item.avatarURL
          )}
        />
      );
    },
  },
];

const GroupsPage = () => {
  const [groups, setGroups] = useState<ITableGroup[]>();
  const [page, setPage] = useState(1);
  const [sortOrder, setSortOrder] = useState("");
  const [filter, setFilter] = useState("");
  const [queryParam, setQueryParam] = useState("");
  const [totalCount, setTotalCount] = useState<number>();

  const navigate = useNavigate();

  useEffect(() => {
    if (queryParam) fetchData();
  }, [queryParam]);

  useEffect(() => {
    setQueryParam(
      `?page=${page}&paginationItemsPerPage=${ITEMS_PER_PAGE}&filter=${filter}&sortBy=name&sortOrder=${sortOrder}`
    );
  }, [page, sortOrder, filter]);

  const fetchData = async () => {
    try {
      const response = await getAllGroups(queryParam);
      setGroups(response.data);
      setTotalCount(response.totalCount);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const handleRowClick = (params: any) => {
    navigate(`${GROUP_DETAILS}/${params.id}`);
  };

  const handlePageChange = (e: any) => {
    setPage(e.page + 1);
  };

  const handleSortChange = () => {
    setSortOrder(toggleSort(sortOrder));
  };

  return (
    <div className="padding-wrapper">
      <SettingsMenu />
      <TitleArea />
      {groups ? (
        <>
          <SearchBar setSearchValue={(value) => setFilter(value)} />
          <DataGrid
            onRowClick={(params) => handleRowClick(params)}
            getRowId={(row) => row.adminGroupId}
            rows={groups}
            disableColumnMenu
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            rowCount={totalCount}
            sx={{ border: 0 }}
            onPaginationModelChange={(e: any) => handlePageChange(e)}
            onSortModelChange={handleSortChange}
            sortingMode="server"
            paginationMode="server"
            sortingOrder={["asc", "desc"]}
            disableColumnSelector={true}
            localeText={{ noRowsLabel: "No data available" }}
          />
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default GroupsPage;
