import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { GROUPS } from "../../common/constants/routes.constants";
import { useNavigate } from "react-router";
import GroupBasicDetails from "./GroupBasicDetails";
import GroupTeachers from "./GroupTeachers";
import GroupActivities from "./GroupActivities";
import GroupAdditionalActivities from "./GroupAdditionalActivities";
import {
  IApiActivitySubtypes,
  IGroupDetailsAdditionalActivity,
  IGroupsTableChild,
  IGroupsTableTeacher,
  IMappedTeacherOptions,
} from "../../common/interfaces/group.interfaces";
import { getKindergartenAdditionalActivities } from "../../api/kindergarten.api";
import { IKindergartenDetailsAdditionalActivity } from "../../common/interfaces/kindergarten.interfaces";
import FormActions, { DataManagement } from "../FormActions/FormActions";
import ConfirmActionModal from "../Modal/ConfirmDeletionModal";
import {
  createOrUpdateGroup,
  deleteGroup,
  getActivitySubtypes,
} from "../../api/groups.api";
import { toast } from "react-toastify";
import { getAllTeachers } from "../../api/teachers.api";
import {
  deepCopyAdditionalActivities,
  grabActivityIdsForValidation,
  mapHexToColor,
  removeWordFromArray,
  transformActivitySubtypes,
  validateObject,
} from "../../common/utils/helpers";
import {
  ChildrenWithStatuses,
  CreateOrUpdateGroupAddActivitiesDto,
  CreateOrUpdateGroupDto,
  CreateOrUpdateGroupTeachersDto,
} from "../../common/dtos/groups.dto";
import { DtoFieldStatus } from "../../common/enums/common.enums";

const GroupForm: FC<{
  handleToggleEdit?: () => void;
  edit?: boolean;
  groupId?: number;
  groupName?: string;
  groupTeachers?: IGroupsTableTeacher[];
  groupActivities?: IApiActivitySubtypes[];
  additionalActivities?: IGroupDetailsAdditionalActivity[];
  groupColor?: string;
  groupChildren?: IGroupsTableChild[];
}> = ({
  handleToggleEdit,
  edit,
  groupId,
  groupName,
  groupActivities,
  additionalActivities,
  groupColor,
  groupTeachers,
  groupChildren,
}) => {
  const navigate = useNavigate();
  const [teachers, setTeachers] = useState<IMappedTeacherOptions[]>([
    { teacherId: -1, teacherName: "", isPrimary: false },
  ]);
  const [activities, setActivities] = useState<IApiActivitySubtypes[]>([]);
  const [otherActivities, setOtherActivities] = useState<
    IGroupDetailsAdditionalActivity[]
  >([
    {
      groupAdditionalActivityId: -1,
      additionalActivityId: -1,
      name: "",
      children: undefined,
      iconURL: "",
      imageURL: "",
    },
  ]);
  const [otherActivityOptions, setOtherActivityOptions] = useState<
    IKindergartenDetailsAdditionalActivity[]
  >([{ additionalActivityId: -1, name: "" }]);
  const nameRef = useRef<HTMLInputElement>();
  const [groupColors, setGroupColors] = useState("");
  const [showDeletionModal, toggleShowDeletionModal] = useState<boolean>();
  const [teacherOptions, setTeacherOptions] = useState<IMappedTeacherOptions[]>(
    []
  );
  const [initialAdditionalActivities, setInitialAdditionalActivities] =
    useState<IGroupDetailsAdditionalActivity[]>([]);
  const [initialTeachers, setInitialTeachers] = useState<IGroupsTableTeacher[]>(
    []
  );
  const [groupActivityOptions, setGroupActivityOptions] = useState<any[]>();
  const [foodValidationArray, setFoodValidationArray] = useState<number[]>([]);
  const [toiletValidationArray, setToiletValidationArray] = useState<number[]>(
    []
  );
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    void fetchData();
    if (edit) populateFields();
    setInitialAdditionalActivities(
      deepCopyAdditionalActivities(additionalActivities ?? [])
    );
    setInitialTeachers(Array.from(groupTeachers ?? []));
  }, []);

  useEffect(() => {
    setErrors((errors) => [...removeWordFromArray("groupColors", errors)]);
  }, [groupColors]);

  const populateFields = () => {
    if (groupName && nameRef.current) {
      nameRef.current.value = groupName;
    }
    groupColor && setGroupColors(groupColor);
    groupActivities && setActivities(groupActivities);
    const mappedTeachers = groupTeachers?.map((item) => ({
      teacherName: item.teacherFullName,
      teacherId: item.teacherId,
      isPrimary: item.isPrimary,
    }));
    groupTeachers && setTeachers(mappedTeachers ?? []);
    additionalActivities && setOtherActivities([...additionalActivities]);
  };

  const changeRefsValue = (
    event: ChangeEvent<HTMLInputElement>,
    customRef: any
  ) => {
    setErrors((errors) => [...removeWordFromArray("groupName", errors)]);
    if (customRef.current) {
      customRef.current.value = event.target.value;
    }
  };

  const extractActivityIds = (activities: IApiActivitySubtypes[]) => {
    const activitySubtypeArray: number[] = [];
    const sleepArray: number[] = [];

    activities.forEach((activity) => {
      const { entityClass, items } = activity;

      if (entityClass === "Food" || entityClass === "Toilet") {
        activitySubtypeArray.push(...items.map((item) => item.activityTypeId));
      } else if (entityClass === "Sleep") {
        sleepArray.push(...items.map((item) => item.activityTypeId));
      }
    });

    return { activitySubtypeArray, sleepArray };
  };

  const compareTeacherToInitial = (teacher: IMappedTeacherOptions) => {
    const indexInInitialArray = initialTeachers.findIndex(
      (item) => item.teacherId === teacher.teacherId
    );

    if (indexInInitialArray === -1) {
      return DtoFieldStatus.New;
    } else {
      if (
        teacher.isPrimary !== initialTeachers[indexInInitialArray].isPrimary
      ) {
        return DtoFieldStatus.Updated;
      } else {
        return DtoFieldStatus.Unaffected;
      }
    }
  };

  const addDeletedTeachers = (
    dtoTeachers: CreateOrUpdateGroupTeachersDto[]
  ) => {
    for (const originalItem of initialTeachers) {
      if (
        dtoTeachers.findIndex(
          (item) => item.teacherId === originalItem.teacherId
        ) === -1
      ) {
        dtoTeachers = [
          ...dtoTeachers,
          {
            teacherId: originalItem.teacherId,
            isPrimary: originalItem.isPrimary,
            status: DtoFieldStatus.Deleted,
          },
        ];
      }
    }

    return dtoTeachers;
  };

  const compareAdditionalActivitiesToInitial = (
    addActivity: IGroupDetailsAdditionalActivity
  ) => {
    let itemStatus = DtoFieldStatus.Unaffected;

    const childrenWithStatuses: ChildrenWithStatuses[] = [];

    const indexInInitialArray = initialAdditionalActivities.findIndex(
      (item) => item.additionalActivityId === addActivity.additionalActivityId
    );

    if (indexInInitialArray === -1) {
      itemStatus = DtoFieldStatus.New;
      for (const child of addActivity.children ?? []) {
        childrenWithStatuses.push({
          id: child.childId,
          status: DtoFieldStatus.New,
        });
      }
    } else {
      const originalChildrenArray = [
        ...(initialAdditionalActivities[indexInInitialArray].children ?? []),
      ];

      for (const child of addActivity.children ?? []) {
        if (
          originalChildrenArray.findIndex(
            (item) => item.childId === child.childId
          ) === -1
        ) {
          childrenWithStatuses.push({
            id: child.childId,
            status: DtoFieldStatus.New,
          });
        } else {
          childrenWithStatuses.push({
            id: child.childId,
            status: DtoFieldStatus.Unaffected,
          });
        }
      }

      for (const originalChild of originalChildrenArray) {
        if (
          childrenWithStatuses.findIndex(
            (item) => originalChild.childId === item.id
          ) === -1
        ) {
          childrenWithStatuses.push({
            id: originalChild.childId,
            status: DtoFieldStatus.Deleted,
          });
        }
      }

      for (const newChild of childrenWithStatuses) {
        if (newChild.status !== DtoFieldStatus.Unaffected) {
          itemStatus = DtoFieldStatus.Updated;
        }
      }
    }

    return { itemStatus, childrenWithStatuses };
  };

  const validateNameAndColor = (groupName: string) => {
    let isValid = true;
    setErrors([]);
    const { errors, valid } = validateObject({ groupName, groupColors });
    isValid = valid;
    setErrors((prevState) => [...prevState, ...errors]);

    return isValid;
  };

  const handleGroupSubmit = async () => {
    let isValid = true;

    const { activitySubtypeArray, sleepArray } = extractActivityIds(activities);

    if (
      !activitySubtypeArray.some((item) => foodValidationArray.includes(item))
    ) {
      isValid = false;
      toast.error("You must select at least one food activity!");
    }

    if (
      !activitySubtypeArray.some((item) => toiletValidationArray.includes(item))
    ) {
      isValid = false;
      toast.error("You must select at least one toilet activity!");
    }

    if (sleepArray.length === 0) {
      isValid = false;
      toast.error("You must select at least one sleep activity!");
    }

    if (!validateNameAndColor(nameRef.current?.value ?? "")) isValid = false;

    for (const teacher of teachers) {
      if (teacher.teacherId === -1) {
        toast.error("Fill out teacher data");
        isValid = false;
      }
    }

    const dtoTeachers: CreateOrUpdateGroupTeachersDto[] = teachers.map(
      (item) => ({
        teacherId: item.teacherId,
        isPrimary: item.isPrimary,
        status: compareTeacherToInitial(item),
      })
    );

    const dtoTeachersWithDeleted = addDeletedTeachers(dtoTeachers);

    const dtoAddActivities: CreateOrUpdateGroupAddActivitiesDto[] = [];

    for (const item of otherActivities) {
      if (item.additionalActivityId === -1) {
        break;
      }
      const { itemStatus, childrenWithStatuses } =
        compareAdditionalActivitiesToInitial(item);

      dtoAddActivities.push({
        additionalActivityId: item.additionalActivityId,
        children: childrenWithStatuses,
        status: itemStatus,
      });
    }

    const request: CreateOrUpdateGroupDto = {
      adminGroupId: groupId ?? null,
      adminGroupName: nameRef.current?.value ?? "",
      adminGroupHexColor: groupColors,
      teachers: dtoTeachersWithDeleted,
      activitySubtypes: activitySubtypeArray,
      sleepingSchedules: sleepArray,
      additionalActivities: dtoAddActivities,
    };

    if (!isValid) return;

    try {
      await createOrUpdateGroup(request);
      toast.success("Successfully uploaded group data!");
      if (edit && handleToggleEdit) {
        handleToggleEdit();
      } else {
        navigate(GROUPS);
      }
    } catch (error: any) {
      const apiMessage = error?.response?.data?.error?.message;
      toast.error(
        apiMessage ?? "Something went wrong while saving group data!"
      );
    }
  };

  const fetchData = async () => {
    try {
      const additionalActivityResponse =
        await getKindergartenAdditionalActivities();
      setOtherActivityOptions(additionalActivityResponse);
      const activitySubtypeResponse = await getActivitySubtypes();
      const mappedActivityOptions = transformActivitySubtypes(
        activitySubtypeResponse
      );
      const { foodIds, toiledIds } = grabActivityIdsForValidation(
        mappedActivityOptions
      );
      setFoodValidationArray(foodIds);
      setToiletValidationArray(toiledIds);
      setGroupActivityOptions(mappedActivityOptions);
      const teacherResponse = await getAllTeachers(
        "?page=1&paginationItemsPerPage=500"
      );
      const mappedTeachers = teacherResponse.data.map((item) => ({
        teacherName: item.name + " " + item.surname,
        teacherId: item.teacherID,
        isPrimary: false,
      }));
      setTeacherOptions(mappedTeachers);
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleGroupDelete = async () => {
    if (edit && groupId) {
      try {
        await deleteGroup(groupId);
        toast.success("Successfully deleted group!");
        navigate(GROUPS);
      } catch (error: any) {
        const apiMessage = error?.response?.data?.error?.message;
        toast.error(
          apiMessage ?? "Something went wrong while deleting group data!"
        );
      }
    }
  };

  return (
    <>
      {showDeletionModal && (
        <ConfirmActionModal
          closeModal={() => toggleShowDeletionModal(false)}
          confirmedAction={handleGroupDelete}
        />
      )}
      <div className="section-wrapper">
        <GroupBasicDetails
          nameRef={nameRef}
          handleNameChange={changeRefsValue}
          groupColor={mapHexToColor(groupColors)}
          setGroupColors={setGroupColors}
          errors={errors}
        />
        <GroupTeachers
          teachers={teachers}
          setTeachers={setTeachers}
          teacherOptions={teacherOptions}
        />
        <GroupActivities
          activities={activities}
          activityOptions={groupActivityOptions ?? []}
          setActivities={setActivities}
        />
        <GroupAdditionalActivities
          otherActivities={otherActivities}
          setOtherActivities={setOtherActivities}
          otherActivityOptions={otherActivityOptions}
          groupChildren={groupChildren ?? []}
          initialAdditionalActivities={initialAdditionalActivities}
          edit={edit}
        />
        {edit && (
          <DataManagement openModal={() => toggleShowDeletionModal(true)} />
        )}
        <FormActions
          edit={edit}
          backAction={
            handleToggleEdit ? handleToggleEdit : () => navigate(GROUPS)
          }
          forwardAction={handleGroupSubmit}
        />
      </div>
    </>
  );
};

export default GroupForm;
