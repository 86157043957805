import React, { useState } from "react";
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import ImageArray from "../Tables/ImageArray";
import ChildMenuItem from "./ChildMenuItem";
import { PlusCircle } from "../../svgs/constants";
import {
  IGroupDetailsAdditionalActivity,
  IGroupsTableChild,
} from "../../common/interfaces/group.interfaces";
import { IKindergartenDetailsAdditionalActivity } from "../../common/interfaces/kindergarten.interfaces";
import { SelectedOptions } from "../../common/enums/common.enums";
import { toast } from "react-toastify";

const GroupAdditionalActivities: React.FC<{
  otherActivities: IGroupDetailsAdditionalActivity[];
  setOtherActivities: React.Dispatch<
    React.SetStateAction<IGroupDetailsAdditionalActivity[]>
  >;
  otherActivityOptions: IKindergartenDetailsAdditionalActivity[];
  groupChildren: IGroupsTableChild[];
  initialAdditionalActivities: IGroupDetailsAdditionalActivity[];
  edit?: boolean;
}> = ({
  otherActivities,
  setOtherActivities,
  otherActivityOptions,
  groupChildren,
  initialAdditionalActivities,
  edit,
}) => {
  const [allChildrenSelected, setAllChildrenSelected] = useState(
    SelectedOptions.Custom
  );

  const handleChangeOtherActivity = (e: any, index: number) => {
    if (
      otherActivities.find(
        (item) => item.additionalActivityId === e.target.value
      )
    ) {
      toast.error("Selected activity is already chosen");
      return;
    }

    const updatedOtherActivities = [...otherActivities];
    updatedOtherActivities[index] = {
      name:
        otherActivityOptions.find(
          (item) => item.additionalActivityId === e.target.value
        )?.name ?? "",
      children: [],
      groupAdditionalActivityId: e.target.value,
      iconURL: "",
      imageURL: "",
      additionalActivityId:
        otherActivityOptions.find(
          (item) => item.additionalActivityId === e.target.value
        )?.additionalActivityId ?? -1,
    };
    setOtherActivities([...updatedOtherActivities]);
  };

  const addOtherActivity = () => {
    if (otherActivities.length === 0) {
      setOtherActivities([
        {
          groupAdditionalActivityId: -1,
          children: undefined,
          name: "",
          iconURL: "",
          imageURL: "",
          additionalActivityId: -1,
        },
      ]);
    }
    if (
      otherActivities.length !== 0 &&
      otherActivities[otherActivities.length - 1].groupAdditionalActivityId !==
        -1
    ) {
      setOtherActivities((items) => [
        ...items,
        {
          groupAdditionalActivityId: -1,
          children: undefined,
          name: "",
          iconURL: "",
          imageURL: "",
          additionalActivityId: -1,
        },
      ]);
    }
  };

  const toggleAllChildrenForActivity = (index: number) => {
    const updatedOtherActivities = [...otherActivities];
    if (allChildrenSelected !== SelectedOptions.All) {
      updatedOtherActivities[index] = {
        ...updatedOtherActivities[index],
        children: [...groupChildren],
      };
      setAllChildrenSelected(SelectedOptions.All);
    } else {
      updatedOtherActivities[index] = {
        ...updatedOtherActivities[index],
        children: [],
      };
      setAllChildrenSelected(SelectedOptions.None);
    }
    setOtherActivities([...updatedOtherActivities]);
  };

  const clearAll = () => {
    setAllChildrenSelected(SelectedOptions.Custom);
  };

  return (
    <div className="section-container">
      <h3>Other Activities</h3>
      <div className="input-section">
        <p style={{ color: "#626262" }}>
          Note: once assigned, activities can not be removed from a group
        </p>
        {otherActivities.map((item, index) => (
          <>
            <TextField
              key={index}
              label="Choose activity"
              select
              disabled={index <= initialAdditionalActivities.length - 1}
              className="color-dropdown"
              value={item.additionalActivityId}
              onChange={(e: any) => handleChangeOtherActivity(e, index)}
              fullWidth
            >
              {otherActivityOptions.map((activity, index) => (
                <MenuItem value={activity.additionalActivityId} key={index}>
                  {activity.name}
                </MenuItem>
              ))}
            </TextField>
            {item.children && edit && (
              <FormControl>
                <InputLabel>Choose children</InputLabel>
                <Select
                  multiple
                  value={item.children}
                  input={<OutlinedInput label="Choose children" />}
                  renderValue={(selected) => (
                    <ImageArray
                      images={item.children!.map(
                        (child) => child.avatarURL ?? ""
                      )}
                    />
                  )}
                >
                  <MenuItem onClick={() => toggleAllChildrenForActivity(index)}>
                    <Checkbox
                      style={{
                        color: "#3F3273",
                      }}
                      checked={allChildrenSelected === SelectedOptions.All}
                    />
                    <ListItemText primary="Select All" />
                  </MenuItem>
                  {groupChildren.map((childItem) => (
                    <ChildMenuItem
                      key={childItem.childId}
                      child={childItem}
                      currentActivity={otherActivities[index]}
                      allChildrenSelected={allChildrenSelected}
                      clearAll={clearAll}
                    />
                  ))}
                </Select>
              </FormControl>
            )}
          </>
        ))}
        <div onClick={addOtherActivity} className="add-parent">
          <PlusCircle />
          Add another activity
        </div>
      </div>
    </div>
  );
};

export default GroupAdditionalActivities;
