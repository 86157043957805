import "./loginPage.scss";
import heroLogo from "../../assets/logoWithName.png";
import { ChangeEvent, useContext, useRef, useState } from "react";
import { LOGIN_API } from "../../common/constants/api.constants";
import { postRequest } from "../../api/http";
import { useNavigate } from "react-router";
import { OVERVIEW } from "../../common/constants/routes.constants";
import { AuthContext } from "../../store/AuthContext";
import { Button, InputAdornment, TextField } from "@mui/material";
import showPasswordIcon from "../../assets/showPasswordIcon.png";
import { toast } from "react-toastify";

function LoginPage() {
  const [errors, setErrors] = useState<string[]>([]);
  const [loggingIn, toggleLoggingIn] = useState(false);

  const authCtx = useContext(AuthContext);

  const usernameRef = useRef<HTMLInputElement>();
  const passwordRef = useRef<HTMLInputElement>();
  const [showPassword, toggleShowPassword] = useState(false);

  const navigate = useNavigate();

  const changeRefValue = (
    event: ChangeEvent<HTMLInputElement>,
    customRef: any
  ) => {
    if (customRef.current) customRef.current.value = event.target.value;
  };

  const handleLogin = async () => {
    toggleLoggingIn(true);
    try {
      const username = usernameRef.current?.value || "";
      const password = passwordRef.current?.value || "";
      if (!validateFields(username, password)) return;
      const response = await postRequest(LOGIN_API, { username, password });
      const { token, refresh_token } = response;
      authCtx.login(token, refresh_token);
      navigate(OVERVIEW);
    } catch (error) {
      toast.error("Invalid credentials", { toastId: "login" });
      toggleLoggingIn(false);
    }
  };

  const validateFields = (username: string, password: string) => {
    let isValid = true;
    setErrors([]);
    if (!username || username === "") {
      setErrors((errors) => [...errors, "username"]);
      isValid = false;
    }
    if (!password || password === "") {
      setErrors((errors) => [...errors, "password"]);
      isValid = false;
    }
    return isValid;
  };

  const handleShowPassword = () => toggleShowPassword(!showPassword);

  const handleEnter = (event: any) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <div className="login-wrapper">
      <div className="image-section">
        <img src={heroLogo} alt="Logo" className="veerny-logo" />
      </div>
      <div className="form-section">
        <div className="form-elements">
          <h2>Login</h2>
          <div className="login-input-fields">
            <div>
              <TextField
                inputRef={usernameRef}
                label="Username *"
                size="medium"
                onInput={(e: ChangeEvent<HTMLInputElement>) =>
                  changeRefValue(e, usernameRef)
                }
                sx={{ width: "100%" }}
                onKeyDown={handleEnter}
              />
              {errors.includes("username") && (
                <p className="error-text">Please enter your username</p>
              )}
            </div>
            <div>
              <TextField
                className="login-input-fields__password"
                inputRef={passwordRef}
                label="Password *"
                size="medium"
                onInput={(e: ChangeEvent<HTMLInputElement>) =>
                  changeRefValue(e, passwordRef)
                }
                type={!showPassword ? "password" : "text"}
                sx={{ width: "100%" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" onClick={handleShowPassword}>
                      <img
                        src={showPasswordIcon}
                        className="show-password"
                        alt="Show password"
                      />
                    </InputAdornment>
                  ),
                }}
                onKeyDown={handleEnter}
              />
              {errors.includes("password") && (
                <p className="error-text">Please enter your password</p>
              )}
            </div>
          </div>
          <Button
            onClick={handleLogin}
            variant="contained"
            className="login-button"
          >
            {loggingIn ? "Logging in..." : "LOGIN"}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
