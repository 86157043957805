import { FC } from "react";
import "./Modal.scss";
import Modal from "./Modal";

const ConfirmActionModal: FC<{
  closeModal: () => void;
  confirmedAction?: () => void;
}> = ({ closeModal, confirmedAction }) => {
  return (
    <Modal title="Are you sure?" closeModal={closeModal}>
      <p style={{ margin: "0" }}>This action can not be reverted</p>
      <div
        className="form-actions"
        style={{ justifyContent: "space-between", marginTop: "48px" }}
      >
        <div className="cancel-action" onClick={closeModal}>
          Cancel
        </div>
        <div
          className="submit-action"
          style={{ color: "white" }}
          onClick={confirmedAction}
        >
          Confirm
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmActionModal;
