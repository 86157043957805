import { Checkbox, ListItemText, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  IGroupDetailsAdditionalActivity,
  IGroupsTableChild,
} from "../../common/interfaces/group.interfaces";
import { SelectedOptions } from "../../common/enums/common.enums";

const ChildMenuItem: React.FC<{
  child: IGroupsTableChild;
  currentActivity: IGroupDetailsAdditionalActivity;
  allChildrenSelected: SelectedOptions;
  clearAll: () => void;
}> = ({ child, currentActivity, allChildrenSelected, clearAll }) => {
  const [isChecked, setIsChecked] = useState(
    currentActivity.children!.some((item) => item.childId === child.childId)
  );

  useEffect(() => {
    if (allChildrenSelected === SelectedOptions.All) setIsChecked(true);
    if (allChildrenSelected === SelectedOptions.None) setIsChecked(false);
  }, [allChildrenSelected]);

  const handleChangeChildren = () => {
    clearAll();
    let found = false;

    if (currentActivity.children) {
      currentActivity.children.forEach((item, childIndex) => {
        if (item.childId === child.childId) {
          found = true;
          currentActivity.children?.splice(childIndex, 1);
          setIsChecked(false);
        }
      });

      if (!found) {
        currentActivity.children!.push(child);
        setIsChecked(true);
      }
    }
  };

  return (
    <MenuItem
      key={child.childId}
      onClick={(e) => {
        e.stopPropagation();
        handleChangeChildren();
      }}
    >
      <Checkbox
        style={{
          color: "#3F3273",
        }}
        checked={isChecked}
      />
      <img src={child.avatarURL} style={{ borderRadius: "50%" }} />
      <ListItemText
        primary={`${child.firstName}` + " " + `${child.lastName}`}
      />
    </MenuItem>
  );
};

export default ChildMenuItem;
