import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import "./ParentForm.scss";
import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { DtoFieldStatus, Relationship } from "../../common/enums/common.enums";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { useNavigate } from "react-router";
import { PARENTS } from "../../common/constants/routes.constants";
import CustomDropdown from "../CustomDropdown/CustomDropdown";
import ConfirmActionModal from "../Modal/ConfirmDeletionModal";
import FormActions, { DataManagement } from "../FormActions/FormActions";
import { createOrUpdateParent, deleteParent } from "../../api/parents.api";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { PlusCircle } from "../../svgs/constants";
import {
  IParentChild,
  ParentFormChildren,
} from "../../common/interfaces/parent.interfaces";
import { getAllChildren } from "../../api/children.api";
import dayjs from "dayjs";
import { CreateOrUpdateParentDto } from "../../common/dtos/parents.dto";
import {
  removeWordFromArray,
  validateObject,
} from "../../common/utils/helpers";
import { Delete } from "@mui/icons-material";
import { toast } from "react-toastify";

const ParentForm: FC<{
  edit?: boolean;
  parentId?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  address?: string;
  phNumber?: string;
  gender?: string;
  children?: IParentChild[];
  date?: string;
  handleToggleEdit?: () => void;
}> = ({
  edit,
  parentId,
  firstName,
  lastName,
  email,
  address,
  phNumber,
  gender,
  children,
  date,
  handleToggleEdit,
}) => {
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [availableChildren, setAvailableChildren] = useState<
    ParentFormChildren[]
  >([]);
  const [selectedChildren, setSelectedChildren] = useState<IParentChild[]>([
    { childId: -1, childRelation: "", isPrimaryCarer: false },
  ]);
  const [showDeletionModal, toggleShowDeletionModal] = useState<boolean>();
  const [chosenGender, setChosenGender] = useState<string>("");
  const [chosenDateOfBirth, setChosenDateOfBirth] =
    useState<dayjs.Dayjs | null>(null);
  const [initialChildren, setInitialChildren] = useState<IParentChild[]>([]);
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    if (children) setInitialChildren(Array.from(children));
  }, []);

  const firstNameRef = useRef<HTMLInputElement>();
  const lastNameRef = useRef<HTMLInputElement>();
  const emailRef = useRef<HTMLInputElement>();
  const addressRef = useRef<HTMLInputElement>();

  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const response = await getAllChildren(
        "?page=1&paginationItemsPerPage=500"
      );
      const mappedResponse = response.data.map((item) => ({
        childId: item.childId,
        childRelation: "",
        isPrimaryCarer: 0,
        name: item.name + " " + item.surname,
        childAvatarURL: item.childAvatarURL ?? "",
        groupName: item.group?.groupName ?? "",
      }));
      setAvailableChildren(mappedResponse);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchData();
    if (edit) {
      populateFields();
    }
  }, []);

  const handleSubmit = async () => {
    let valid = true;

    if (selectedChildren.length === 0) {
      toast.error("No children selected!");
      valid = false;
    }

    if (valid) {
      for (const child of selectedChildren) {
        if (child.childId === -1) {
          toast.error("Fill out all child data!");
          valid = false;
        }
      }
    }

    const dtoChildren = selectedChildren.map((item) => ({
      childId: item.childId,
      childRelation: item.childRelation?.toLowerCase(),
      isPrimary: item.isPrimaryCarer,
      status: compareToInitialArray(item),
    }));

    const dtoChildrenWithDeleted = addDeletedChildren(dtoChildren);

    const parentsData: CreateOrUpdateParentDto = {
      parentId: parentId ?? null,
      name: firstNameRef.current?.value!,
      surname: lastNameRef.current?.value!,
      email: emailRef.current?.value!,
      address: addressRef.current?.value!,
      gender: chosenGender,
      dateOfBirth: chosenDateOfBirth
        ? chosenDateOfBirth.toISOString().slice(0, 10)
        : "",
      phone: phoneNumber,
      children: dtoChildrenWithDeleted,
    };

    if (!validateParentsForm(parentsData)) valid = false;

    if (!valid) return;

    try {
      await createOrUpdateParent(parentsData);
      toast.success("Successfully uploaded parent data!");
      if (edit && handleToggleEdit) {
        handleToggleEdit();
      } else {
        navigate(PARENTS);
      }
    } catch (error: any) {
      const apiMessage = error?.response?.data?.error?.message;
      toast.error(
        apiMessage ?? "Something went wrong while saving parent data!"
      );
    }
  };

  const changeRefValue = (
    event: ChangeEvent<HTMLInputElement>,
    customRef: any,
    fieldName?: string
  ) => {
    setErrors((errors) => [...removeWordFromArray(fieldName!, errors)]);
    if (customRef.current) {
      customRef.current.value = event.target.value;
    }
  };

  useEffect(() => {
    setErrors((errors) => [...removeWordFromArray("gender", errors)]);
  }, [chosenGender]);

  useEffect(() => {
    setErrors((errors) => [...removeWordFromArray("phone", errors)]);
  }, [phoneNumber]);

  const handlePhoneChange = (value: string | undefined) => {
    setPhoneNumber(value || "");
  };

  const handleRelationChange = (
    e: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    setSelectedChildren((prevState) => {
      prevState[index] = {
        ...prevState[index],
        childRelation: e.target.value,
      };
      return [...prevState];
    });
  };

  const handleChildChange = (e: any, index: number) => {
    setSelectedChildren((prevState) => {
      prevState[index] = {
        childId: e.target.value,
        childRelation: "",
        isPrimaryCarer: false,
      };
      return [...prevState];
    });
  };

  const handleSetPrimaryChange = (item: IParentChild) => {
    if (item.childId === -1) return;

    setSelectedChildren((prevState) => {
      const foundChild = prevState.find(
        (child) => child.childId === item.childId
      );
      if (foundChild) {
        foundChild.isPrimaryCarer = !foundChild?.isPrimaryCarer;
      }
      return [...prevState];
    });
  };

  const addChild = () => {
    const newChildren = {
      childId: -1,
      childRelation: "",
      isPrimaryCarer: false,
    };
    setSelectedChildren((prevState) => [...prevState, newChildren]);
  };

  const compareToInitialArray = (arg: IParentChild) => {
    const indexInInitialArray = initialChildren.findIndex(
      (item) => item.childId === arg.childId
    );

    if (indexInInitialArray === -1) {
      return DtoFieldStatus.New;
    } else {
      if (checkChildForChange(arg)) {
        return DtoFieldStatus.Updated;
      }
      return DtoFieldStatus.Unaffected;
    }
  };

  const addDeletedChildren = (dtoChildren: any[]) => {
    for (const originalChild of initialChildren) {
      if (
        dtoChildren.findIndex(
          (item) => item.childId === originalChild.childId
        ) === -1
      ) {
        dtoChildren = [
          ...dtoChildren,
          {
            childId: originalChild.childId,
            childRelation: originalChild.childRelation,
            isPrimary: originalChild.isPrimaryCarer,
            status: DtoFieldStatus.Deleted,
          },
        ];
      }
    }

    return dtoChildren;
  };

  const checkChildForChange = (child: IParentChild) => {
    let hasChanged = false;

    const initialChild = initialChildren.find(
      (item) => item.childId === child.childId
    );

    if (child.childRelation !== initialChild?.childRelation) {
      hasChanged = true;
    }

    if (child.isPrimaryCarer !== initialChild?.isPrimaryCarer) {
      hasChanged = true;
    }

    return hasChanged;
  };

  const populateFields = () => {
    if (date) {
      const dateFromString = new Date(date);
      const dayJsDate = dayjs(dateFromString);
      setChosenDateOfBirth(dayJsDate);
    }
    if (firstNameRef.current) firstNameRef.current.value = firstName ?? "";
    if (lastNameRef.current) lastNameRef.current.value = lastName ?? "";
    if (emailRef.current) emailRef.current.value = email ?? "";
    if (addressRef.current) addressRef.current.value = address ?? "";
    setPhoneNumber(phNumber ?? "");
    setChosenGender(gender ?? "");
    const mappedChosenChildren = children?.map((item) => ({
      childId: item.childId,
      childRelation: item.childRelation,
      isPrimaryCarer: item.isPrimaryCarer,
    }));
    setSelectedChildren(mappedChosenChildren ?? []);
  };

  const handleFormCancel = () => {
    if (edit && handleToggleEdit) {
      handleToggleEdit();
    } else navigate(PARENTS);
  };

  const handleDateChange = (date: dayjs.Dayjs | null) => {
    setErrors((errors) => [...removeWordFromArray("dateOfBirth", errors)]);
    setChosenDateOfBirth(date);
  };

  const validateParentsForm = (parentData: CreateOrUpdateParentDto) => {
    let isValid = true;
    setErrors([]);
    const { errors, valid } = validateObject(parentData);
    isValid = valid;
    setErrors((prevState) => [...prevState, ...errors]);
    return isValid;
  };

  const handleParentDelete = async () => {
    if (edit && parentId)
      try {
        await deleteParent(parentId);
        toast.success("Successfully deleted parent!");
        navigate(PARENTS);
      } catch (error: any) {
        const apiMessage = error?.response?.data?.error?.message;
        toast.error(
          apiMessage ?? "Something went wrong while deleting parent data!"
        );
      }
  };

  const handleDeleteChild = (index: number) => {
    setSelectedChildren((prevState) => {
      const updatedParents = [...prevState];
      updatedParents.splice(index, 1);
      return updatedParents;
    });
  };

  const handleGenderChange = (e: ChangeEvent<HTMLInputElement>) => {
    setErrors((errors) => [...removeWordFromArray("gender", errors)]);
    setChosenGender(e.target.value);
  };

  return (
    <>
      {showDeletionModal && (
        <ConfirmActionModal
          closeModal={() => toggleShowDeletionModal(false)}
          confirmedAction={handleParentDelete}
        />
      )}
      <div className="section-wrapper">
        <div className="section-container">
          <h3>Basic Details</h3>
          <div className="input-section">
            <div>
              <TextField
                inputRef={firstNameRef}
                label="First name *"
                size="medium"
                onInput={(e: ChangeEvent<HTMLInputElement>) =>
                  changeRefValue(e, firstNameRef, "name")
                }
                sx={{ width: "100%" }}
              />
              {errors.includes("name") && (
                <p className="error-text">Name can not be empty</p>
              )}
            </div>
            <div>
              <TextField
                inputRef={lastNameRef}
                label="Last name *"
                size="medium"
                onInput={(e: ChangeEvent<HTMLInputElement>) =>
                  changeRefValue(e, lastNameRef, "surname")
                }
                sx={{ width: "100%" }}
              />
              {errors.includes("surname") && (
                <p className="error-text">Surname can not be empty</p>
              )}
            </div>
            <div>
              <TextField
                inputRef={emailRef}
                label="Email *"
                size="medium"
                type="email"
                onInput={(e: ChangeEvent<HTMLInputElement>) =>
                  changeRefValue(e, emailRef, "email")
                }
                sx={{ width: "100%" }}
              />
              {errors.includes("email") && (
                <p className="error-text">Email can not be empty</p>
              )}
            </div>
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date of Birth *"
                  sx={{ width: "100%" }}
                  value={chosenDateOfBirth}
                  onChange={handleDateChange}
                />
              </LocalizationProvider>
              {errors.includes("dateOfBirth") && (
                <p className="error-text">Date of birth can not be empty</p>
              )}
            </div>
            <div className="radio-wrapper">
              <h4>Gender</h4>
              <RadioGroup
                aria-label="gender"
                name="gender"
                value={chosenGender}
                onChange={handleGenderChange}
                row
              >
                <FormControlLabel
                  value="m"
                  control={<Radio style={{ color: "#3f3273" }} />}
                  label="Male"
                />
                <FormControlLabel
                  value="f"
                  control={<Radio style={{ color: "#3f3273" }} />}
                  label="Female"
                />
              </RadioGroup>
              {errors.includes("gender") && (
                <p className="error-text">Gender must be selected</p>
              )}
            </div>
            <div>
              <TextField
                inputRef={addressRef}
                label="Address *"
                size="medium"
                onInput={(e: ChangeEvent<HTMLInputElement>) =>
                  changeRefValue(e, addressRef, "address")
                }
                sx={{ width: "100%" }}
              />
              {errors.includes("address") && (
                <p className="error-text">Address can not be empty</p>
              )}
            </div>
            <div>
              <PhoneInput
                country={"rs"}
                preferredCountries={["rs", "pt", "no", "gb"]}
                specialLabel="Phone Number *"
                value={phoneNumber}
                onChange={(phone) => handlePhoneChange(phone)}
              />
              {errors.includes("phone") && (
                <p className="error-text">Phone number can not be empty</p>
              )}
            </div>
          </div>
        </div>
        <div className="section-container">
          <h3>Children</h3>
          <div className="input-section">
            <p style={{ color: "#626262" }}>
              Note: you must assign at least one child to a parent
            </p>
            {selectedChildren.map((item, index) => (
              <Stack
                key={item.childId}
                direction="row"
                className="add-another-entity-row"
                alignItems="center"
              >
                <Stack direction="column" className="input-section-fields">
                  <h4>Child {index + 1}</h4>
                  <CustomDropdown
                    label="Choose a child"
                    chosenOption={item.childId}
                    setChosenOption={(e: any) => handleChildChange(e, index)}
                    availableChildren={availableChildren}
                  />
                  <TextField
                    label="Choose a relation"
                    select
                    disabled={item.childId === -1}
                    value={item.childRelation}
                    onChange={(e: any) => handleRelationChange(e, index)}
                    fullWidth
                  >
                    {Object.values(Relationship).map((item, index) => (
                      <MenuItem value={item.toLowerCase()} key={index}>
                        {item}
                      </MenuItem>
                    ))}
                  </TextField>
                  <FormControlLabel
                    sx={{ color: "#626262" }}
                    control={
                      <Checkbox
                        style={{ color: "#3f3273" }}
                        checked={
                          item.isPrimaryCarer !== undefined &&
                          item.isPrimaryCarer
                        }
                        onChange={() => handleSetPrimaryChange(item)}
                      />
                    }
                    label="Primary carer"
                  />
                </Stack>
                <Delete
                  className="delete-entity-row"
                  onClick={() => handleDeleteChild(index)}
                />
              </Stack>
            ))}
            <div onClick={addChild} className="add-parent">
              <PlusCircle />
              Add another child
            </div>
          </div>
        </div>
        {edit && (
          <DataManagement openModal={() => toggleShowDeletionModal(true)} />
        )}
        <FormActions
          forwardAction={handleSubmit}
          edit={edit}
          backAction={handleFormCancel}
        />
      </div>
    </>
  );
};

export default ParentForm;
