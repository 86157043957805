import { Route, Routes as Router } from "react-router";
import {
  CHILDREN,
  CHILD_DETAILS,
  CHILD_FORM,
  GROUPS,
  GROUP_DETAILS,
  LOGIN,
  PARENTS,
  PARENT_DETAILS,
  PARENT_FORM,
  SETTINGS,
  TEACHERS,
  TEACHER_DETAILS,
  TEACHER_FORM,
  GROUP_FORM,
  OVERVIEW,
} from "./common/constants/routes.constants";
import LoginPage from "./pages/LoginPage";
import GroupsPage from "./pages/GroupsPage";
import BasicLayout from "./components/Layout/BasicLayout/BasicLayout";
import ParentsPage from "./pages/ParentsPage";
import TeachersPage from "./pages/TeachersPage";
import ChildrenPage from "./pages/ChildrenPage";
import ParentFormPage from "./pages/ParentFormPage";
import ChildFormPage from "./pages/ChildFormPage";
import TeacherFormPage from "./pages/TeacherFormPage";
import SettingsPage from "./pages/SettingsPage";
import OverviewPage from "./pages/OverviewPage";
import GroupsFormPage from "./pages/GroupsFormPage";
import ParentDetailsPage from "./pages/ParentDetailsPage";
import TeacherDetailsPage from "./pages/TeacherDetailsPage";
import ChildDetailsPage from "./pages/ChildDetailsPage";
import GroupDetailsPage from "./pages/GroupDetailsPage";
import PageNotFound from "./pages/PageNotFound/PageNotFound";

const Routes = () => {
  return (
    <Router>
      <Route element={<LoginPage />} path={LOGIN} />
      <Route element={<BasicLayout content={<GroupsPage />} />} path={GROUPS} />
      <Route
        element={<BasicLayout content={<GroupsFormPage />} />}
        path={GROUP_FORM}
      />
      <Route
        element={<BasicLayout content={<GroupDetailsPage />} />}
        path={`${GROUP_DETAILS}/:groupId`}
      />
      <Route
        element={<BasicLayout content={<ParentsPage />} />}
        path={PARENTS}
      />
      <Route
        element={<BasicLayout content={<ParentFormPage />} />}
        path={PARENT_FORM}
      />
      <Route
        element={<BasicLayout content={<ParentDetailsPage />} />}
        path={`${PARENT_DETAILS}/:parentId`}
      />
      <Route
        element={<BasicLayout content={<TeachersPage />} />}
        path={TEACHERS}
      />
      <Route
        element={<BasicLayout content={<TeacherFormPage />} />}
        path={TEACHER_FORM}
      />
      <Route
        element={<BasicLayout content={<TeacherDetailsPage />} />}
        path={`${TEACHER_DETAILS}/:teacherId`}
      />
      <Route
        element={<BasicLayout content={<ChildrenPage />} />}
        path={CHILDREN}
      />
      <Route
        element={<BasicLayout content={<ChildFormPage />} />}
        path={CHILD_FORM}
      />
      <Route
        element={<BasicLayout content={<ChildDetailsPage />} />}
        path={`${CHILD_DETAILS}/:childId`}
      />
      <Route
        element={<BasicLayout content={<SettingsPage />} />}
        path={SETTINGS}
      />
      <Route
        element={<BasicLayout content={<OverviewPage />} />}
        path={OVERVIEW}
      />
      <Route element={<PageNotFound />} path="*" />
    </Router>
  );
};

export default Routes;
