import { FC } from "react";
import backArrow from "../../assets/arrow_back.svg";

const FormActions: FC<{
  edit?: boolean;
  backAction: () => void;
  forwardAction?: () => void;
}> = ({ edit, backAction, forwardAction }) => {
  return (
    <div className="form-actions">
      <div className="cancel-action" onClick={backAction}>
        Cancel
      </div>
      <div className="submit-action" onClick={forwardAction}>
        {edit ? "Edit" : "Create"}
      </div>
    </div>
  );
};

export const DataManagement: FC<{ openModal: () => void }> = ({
  openModal,
}) => {
  return (
    <div className="section-container">
      <h3>Data Management</h3>
      <div className="delete-container" onClick={openModal}>
        Delete Account
      </div>
    </div>
  );
};

export const ReturnSection: FC<{ handleToggleEdit: () => void }> = ({
  handleToggleEdit,
}) => {
  return (
    <>
      <div className="back-container" onClick={handleToggleEdit}>
        <img src={backArrow} alt="Back arrow" />
        Return
      </div>
      <h1>Edit</h1>
    </>
  );
};

export default FormActions;
