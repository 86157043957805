import { FC } from "react";
import { PlusCircle } from "../../svgs/constants";
import { IMappedTeacherOptions } from "../../common/interfaces/group.interfaces";
import { MenuItem, TextField, Checkbox, FormControlLabel } from "@mui/material";
import { toast } from "react-toastify";

const GroupTeachers: FC<{
  teachers: IMappedTeacherOptions[];
  setTeachers: React.Dispatch<React.SetStateAction<IMappedTeacherOptions[]>>;
  teacherOptions: IMappedTeacherOptions[];
}> = ({ teachers, setTeachers, teacherOptions }) => {
  const handleChangeGroupTeacher = (
    teacher: IMappedTeacherOptions,
    index: number
  ) => {
    if (
      teachers.findIndex((item) => item.teacherId === teacher.teacherId) !== -1
    ) {
      toast.error("Chosen teacher is already selected!");
      return;
    }

    const updatedTeachers = [...teachers];
    updatedTeachers[index] = teacher;
    setTeachers([...updatedTeachers]);
  };

  const addTeacher = () => {
    const newTeachers = [
      ...teachers,
      {
        teacherId: -1,
        teacherName: "",
        isPrimary: false,
      },
    ];
    setTeachers(newTeachers);
  };

  const handlePrimaryChange = (e: any, index: number) => {
    const updatedTeachers = [...teachers];
    updatedTeachers[index] = {
      ...updatedTeachers[index],
      isPrimary: !updatedTeachers[index].isPrimary,
    };
    setTeachers([...updatedTeachers]);
  };

  return (
    <div className="section-container">
      <h3>Teachers</h3>
      <div className="input-section">
        {teachers.map((item, index) => (
          <div>
            <TextField
              label="Choose a teacher"
              select
              className="color-dropdown"
              value={item.teacherId}
              fullWidth
            >
              {teacherOptions.map((teacher) => (
                <MenuItem
                  value={teacher.teacherId}
                  key={teacher.teacherId}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    handleChangeGroupTeacher(teacher, index);
                  }}
                >
                  {teacher.teacherName}
                </MenuItem>
              ))}
            </TextField>
            <FormControlLabel
              sx={{ color: "#626262", marginTop: 2 }}
              control={
                <Checkbox
                  style={{ color: "#3f3273" }}
                  checked={item.isPrimary}
                  onChange={(e: any) => handlePrimaryChange(e, index)}
                />
              }
              label="Primary carer"
            />
          </div>
        ))}
        <div onClick={addTeacher} className="add-parent">
          <PlusCircle />
          Add another teacher
        </div>
      </div>
    </div>
  );
};

export default GroupTeachers;
