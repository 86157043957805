import { ChangeEvent, FC, MutableRefObject } from "react";
import "react-phone-input-2/lib/material.css";
import { FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import { Gender } from "../../../common/enums/common.enums";

const TeacherBasicInfoSection: FC<{
  firstNameRef: MutableRefObject<HTMLInputElement | undefined>;
  lastNameRef: MutableRefObject<HTMLInputElement | undefined>;
  emailRef: MutableRefObject<HTMLInputElement | undefined>;
  addressRef: MutableRefObject<HTMLInputElement | undefined>;
  gender: string;
  phoneNumber: string;
  setGender: React.Dispatch<React.SetStateAction<string>>;
  setPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
  changeRefValue: (
    event: ChangeEvent<HTMLInputElement>,
    customRef: any,
    fieldName?: string
  ) => void;
  edit?: boolean;
  errors: string[];
}> = ({
  edit,
  gender,
  phoneNumber,
  addressRef,
  emailRef,
  firstNameRef,
  lastNameRef,
  setPhoneNumber,
  setGender,
  changeRefValue,
  errors,
}) => {
  const handlePhoneChange = (value: string | undefined) => {
    setPhoneNumber(value || "");
  };

  const handleGenderChange = (e: ChangeEvent<HTMLInputElement>) => {
    setGender(e.target.value);
  };

  return (
    <div className="section-container">
      <h3>Basic Details</h3>
      <div className="input-section">
        <div>
          <TextField
            label="First name *"
            inputRef={firstNameRef}
            size="medium"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              changeRefValue(e, firstNameRef, "name")
            }
            sx={{ width: "100%" }}
          />
          {errors.includes("name") && (
            <p className="error-text">Name can not be empty</p>
          )}
        </div>
        <div>
          <TextField
            label="Last name *"
            inputRef={lastNameRef}
            size="medium"
            onInput={(e: ChangeEvent<HTMLInputElement>) =>
              changeRefValue(e, lastNameRef, "surname")
            }
            sx={{ width: "100%" }}
          />
          {errors.includes("surname") && (
            <p className="error-text">Surname can not be empty</p>
          )}
        </div>
        <div>
          <TextField
            label="Email *"
            inputRef={emailRef}
            size="medium"
            type="email"
            onInput={(e: ChangeEvent<HTMLInputElement>) =>
              changeRefValue(e, emailRef, "email")
            }
            sx={{ width: "100%" }}
          />
          {errors.includes("email") && (
            <p className="error-text">Email can not be empty</p>
          )}
        </div>
        <div>
          <PhoneInput
            country={"rs"}
            preferredCountries={["rs", "pt", "no", "gb"]}
            specialLabel="Phone Number *"
            value={phoneNumber}
            onChange={(phone) => handlePhoneChange(phone)}
          />
          {errors.includes("phone") && (
            <p className="error-text">Phone can not be empty</p>
          )}
        </div>
        <div className="radio-wrapper">
          <h4>Gender</h4>
          <RadioGroup
            aria-label="gender"
            name="gender"
            value={gender}
            onChange={handleGenderChange}
            row
          >
            <FormControlLabel
              value={Gender.Male}
              control={<Radio style={{ color: "#3f3273" }} />}
              label="Male"
            />
            <FormControlLabel
              value={Gender.Female}
              control={<Radio style={{ color: "#3f3273" }} />}
              label="Female"
            />
          </RadioGroup>
          {errors.includes("gender") && (
            <p className="error-text">You must select a gender</p>
          )}
        </div>
        <div>
          <TextField
            label="Address *"
            inputRef={addressRef}
            size="medium"
            onInput={(e: ChangeEvent<HTMLInputElement>) =>
              changeRefValue(e, addressRef, "address")
            }
            sx={{ width: "100%" }}
          />
          {errors.includes("address") && (
            <p className="error-text">Address can not be empty</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default TeacherBasicInfoSection;
