import {
  UpdateKindergartenAdditionalActivitiesRequestDto,
  UpdateKindergartenInfoDto,
  UpdateKindergartenLanguagesDto,
} from "../common/dtos/kindergarten.dto";
import {
  IKindergartenDetailsAdditionalActivity,
  IKindergartenInfo,
  ILanguage,
} from "../common/interfaces/kindergarten.interfaces";
import {
  KINDERGARTEN,
  KINDERGARTEN_ADD_ACTIVITIES,
  ADMIN,
  LANGUAGES,
  DETAILS,
} from "./api.routes";
import { getRequest, postMultiPart, postRequest } from "./http";

export function getKindergartenInfo(): Promise<IKindergartenInfo> {
  return getRequest(`${KINDERGARTEN}/${DETAILS}`);
}

export function getKindergartenLanguages(): Promise<ILanguage[]> {
  return getRequest(`${ADMIN}/${LANGUAGES}`);
}

export function getKindergartenAdditionalActivities(): Promise<
  IKindergartenDetailsAdditionalActivity[]
> {
  return getRequest(`${ADMIN}/${KINDERGARTEN_ADD_ACTIVITIES}`);
}

export function updateKindergartenInfo(data: UpdateKindergartenInfoDto) {
  const body = new FormData();
  body.append("kindergartenInfo", JSON.stringify(data.kindergartenInfo));
  if (data.logo) body.append("logo", data.logo);
  return postMultiPart(`${KINDERGARTEN}/${DETAILS}`, body);
}

export function updateKindergartenLanguages(
  data: UpdateKindergartenLanguagesDto
) {
  return postRequest(`${ADMIN}/${LANGUAGES}`, data);
}

export function updateKindergartenAdditionalActivities(
  data: UpdateKindergartenAdditionalActivitiesRequestDto
) {
  return postRequest(`${ADMIN}/${KINDERGARTEN_ADD_ACTIVITIES}`, data);
}
