import "./SettingsMenu.scss";
import gbFlag from "../../assets/gbFlagFull.png";
import { useContext, useEffect, useRef, useState } from "react";
import { LANGUAGE_OPTIONS } from "../../common/constants/common.constants";
import { useNavigate } from "react-router";
import { LOGIN } from "../../common/constants/routes.constants";
import { AuthContext } from "../../store/AuthContext";
import PersonIcon from "@mui/icons-material/Person";

const SettingsMenu = () => {
  const [logoutModal, toggleLogoutModal] = useState<boolean>(false);
  const [languageModal, toggleLanguageModal] = useState<boolean>(false);

  const ctx = useContext(AuthContext);

  const navigate = useNavigate();

  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        toggleLanguageModal(false);
        toggleLogoutModal(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  const openLanguageMenu = () => {
    toggleLogoutModal(false);
    toggleLanguageModal(!languageModal);
  };

  const openLogoutMenu = () => {
    toggleLanguageModal(false);
    toggleLogoutModal(!logoutModal);
  };

  const handleLanguageChange = (chosenLanguage: string) => {
    toggleLanguageModal(false);
  };

  const handleLogout = () => {
    ctx.logout();
    navigate(LOGIN);
  };

  return (
    <div className="menu-container" ref={menuRef}>
      <div className="initials-container" onClick={openLogoutMenu}>
        <PersonIcon />
      </div>
      {logoutModal && (
        <div className="logout-submenu">
          <div className="logout-user-info">
            <p className="logout-heading">Admin</p>
          </div>
          <div className="logout-action" onClick={handleLogout}>
            <p className="logout-heading">Logout</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default SettingsMenu;
