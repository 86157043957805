import { FC, useEffect, useState } from "react";
import "./CustomDropdown.scss";
import { Box, MenuItem, TextField } from "@mui/material";
import { ParentFormChildren } from "../../common/interfaces/parent.interfaces";

const CustomDropdown: FC<{
  label: string;
  chosenOption: number | undefined;
  setChosenOption: (e: any, index: number) => void;
  index?: number;
  availableChildren?: ParentFormChildren[];
}> = ({ label, chosenOption, setChosenOption, index, availableChildren }) => {
  const [filteredOptions, setFilteredOptions] = useState<ParentFormChildren[]>(
    []
  );

  useEffect(() => {
    setFilteredOptions(availableChildren ?? []);
  }, [availableChildren]);

  const handleChange = (event: any) => {
    setChosenOption(event, index ?? 0);
  };

  return (
    <Box>
      <TextField
        label={label}
        select
        value={chosenOption}
        onChange={handleChange}
        fullWidth
      >
        {filteredOptions.map((item) => (
          <MenuItem value={item.childId} key={item.childId}>
            <DropdownOption
              group={item.groupName}
              id={item.childId}
              name={item.name}
              avatar={item.childAvatarURL}
            />
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
};

const DropdownOption: FC<{
  name: string;
  id: number;
  group: string;
  avatar: string | undefined;
}> = ({ id, name, group, avatar }) => {
  return (
    <div className="child-option">
      <div className="child-data">
        <img src={avatar} alt="child" />
        {name}
      </div>
      <div>{group}</div>
    </div>
  );
};

export default CustomDropdown;
