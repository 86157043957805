import React from "react";
import "../Tables/Tables.scss";
import { mapColorsToHex } from "../../common/utils/helpers";

const GroupColorCell: React.FC<{ color: string; hex?: boolean }> = ({
  color,
  hex,
}) => {
  return (
    <div className="group-color-wrapper">
      <div
        className="color-circle"
        style={{ background: `${hex ? mapColorsToHex(color) : color}` }}
      ></div>
      {color}
    </div>
  );
};

export default GroupColorCell;
