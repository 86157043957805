import "./Sidebar.scss";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import {
  ChildrenIcon,
  GroupsIcon,
  HomeIcon,
  KindergartenIconCircle,
  ParentsIcon,
  SettingsIcon,
  TeachersIcon,
} from "../../../svgs/constants";
import { useNavigate } from "react-router";
import {
  CHILDREN,
  GROUPS,
  OVERVIEW,
  PARENTS,
  SETTINGS,
  TEACHERS,
} from "../../../common/constants/routes.constants";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const ACTIVE_TAB: Record<string, string> = {
  "/": "overview",
  "/settings": "settings",
  "/parents": "parents",
  "/children": "children",
  "/teachers": "teachers",
  "/groups": "groups",
};

const Sidebar = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<string | null>(null);
  const [kindergartenName, setKindergartenName] = useState("Kindergarten");
  const [kindergartenAddress, setKindergartenAddress] = useState("Address");

  const location = useLocation();

  useEffect(() => {
    const storedData = localStorage.getItem("kindergartenBasicInfo");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setKindergartenName(parsedData.name);
      setKindergartenAddress(parsedData.address);
    }
  }, []);

  useEffect(() => {
    setActiveTab(ACTIVE_TAB[location.pathname]);
  }, [location.pathname]);

  return (
    <Box className="sidebar" sx={{ p: [3, 2] }}>
      <div className="inner-sidebar">
        <Stack direction="row" className="sidebar-title">
          <div className="logo-wrapper">
            <KindergartenIconCircle />
          </div>
          <div>
            <h2>{kindergartenName}</h2>
            <span>{kindergartenAddress}</span>
          </div>
        </Stack>
        <nav className="primary-navigation">
          <List>
            <ListItem
              disablePadding
              onClick={() => navigate(OVERVIEW)}
              className={activeTab === "overview" ? "active" : ""}
            >
              <ListItemButton>
                <ListItemIcon className="sidebar-icon">
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary="Overview" />
              </ListItemButton>
            </ListItem>
            <ListItem
              disablePadding
              onClick={() => navigate(SETTINGS)}
              className={activeTab === "settings" ? "active" : ""}
            >
              <ListItemButton>
                <ListItemIcon className="sidebar-icon">
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Settings" />
              </ListItemButton>
            </ListItem>
          </List>
        </nav>
        <h3>Concepts</h3>
        <nav className="secondary-navigation">
          <List sx={{ p: 1 }}>
            <ListItem
              disablePadding
              onClick={() => navigate(GROUPS)}
              className={activeTab === "groups" ? "active" : ""}
            >
              <ListItemButton>
                <ListItemIcon className="sidebar-icon">
                  <GroupsIcon />
                </ListItemIcon>
                <ListItemText primary="Groups" />
              </ListItemButton>
            </ListItem>
            <ListItem
              disablePadding
              onClick={() => navigate(TEACHERS)}
              className={activeTab === "teachers" ? "active" : ""}
            >
              <ListItemButton>
                <ListItemIcon className="sidebar-icon">
                  <TeachersIcon />
                </ListItemIcon>
                <ListItemText primary="Teachers" />
              </ListItemButton>
            </ListItem>
            <ListItem
              disablePadding
              onClick={() => navigate(CHILDREN)}
              className={activeTab === "children" ? "active" : ""}
            >
              <ListItemButton>
                <ListItemIcon className="sidebar-icon">
                  <ChildrenIcon />
                </ListItemIcon>
                <ListItemText primary="Children" />
              </ListItemButton>
            </ListItem>
            <ListItem
              disablePadding
              onClick={() => navigate(PARENTS)}
              className={activeTab === "parents" ? "active" : ""}
            >
              <ListItemButton>
                <ListItemIcon className="sidebar-icon">
                  <ParentsIcon />
                </ListItemIcon>
                <ListItemText primary="Parents" />
              </ListItemButton>
            </ListItem>
          </List>
        </nav>
      </div>
    </Box>
  );
};

export default Sidebar;
