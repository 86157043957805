import SettingsMenu from "../../components/SettingsMenu/SettingsMenu";
import { FC, useEffect, useState } from "react";
import "./SettingsPage.scss";
import DetailsPageHeader from "../../components/DetailsPageHeader/DetailsPageHeader";
import SettingsForm from "../../components/SettingsForm/SettingsForm";
import {
  getKindergartenAdditionalActivities,
  getKindergartenLanguages,
} from "../../api/kindergarten.api";
import {
  IKindergartenDetailsAdditionalActivity,
  ILanguage,
} from "../../common/interfaces/kindergarten.interfaces";
import { ReturnSection } from "../../components/FormActions/FormActions";
import Loader from "../../components/Loader/Loader";
import {
  getLanguageDetailsFromCode,
  sortAndFilterLanguages,
} from "../../common/utils/helpers";

const SettingsPage: FC<{}> = () => {
  const [editing, toggleEditing] = useState<boolean>(false);
  const [languageOptions, setLanguageOptions] = useState<ILanguage[]>();
  const [additionalActivities, setAdditionalActivities] =
    useState<IKindergartenDetailsAdditionalActivity[]>();

  useEffect(() => {
    if (!editing) fetchData();
  }, [editing]);

  const handleToggleEdit = () => {
    toggleEditing(!editing);
  };

  const fetchData = async () => {
    try {
      const languageResponse = await getKindergartenLanguages();
      setLanguageOptions(sortAndFilterLanguages(languageResponse));
      const activityResponse = await getKindergartenAdditionalActivities();
      setAdditionalActivities(activityResponse);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  return (
    <div className="padding-wrapper">
      <SettingsMenu />
      {languageOptions && additionalActivities ? (
        <>
          {!editing ? (
            <>
              <DetailsPageHeader
                title="Settings"
                handleState={handleToggleEdit}
              />
              <div className="details-page-layout">
                <div className="details-page-section" style={{ width: "30%" }}>
                  <div className="details-page-section-header">Languages</div>
                  {languageOptions && languageOptions.length === 0 ? (
                    <div className="details-page-section-row">No data</div>
                  ) : (
                    languageOptions.map((item, index) => (
                      <div key={index} className="details-page-section-row">
                        <img
                          src={getLanguageDetailsFromCode(item.name)?.imageUrl}
                          alt="flag"
                        />
                        {getLanguageDetailsFromCode(item.name)?.label}
                        {item.isDefaultLanguage && " (default)"}
                      </div>
                    ))
                  )}
                </div>
                <div className="details-page-section">
                  <div className="details-page-section-header">
                    Other activities
                  </div>
                  {additionalActivities && additionalActivities.length === 0 ? (
                    <div className="details-page-section-row">No data</div>
                  ) : (
                    additionalActivities.map((item) => (
                      <div
                        key={item.additionalActivityId}
                        className="details-page-section-row"
                      >
                        {item.name}
                      </div>
                    ))
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              <ReturnSection handleToggleEdit={handleToggleEdit} />
              <SettingsForm
                activities={additionalActivities}
                languages={languageOptions}
                handleState={handleToggleEdit}
              />
            </>
          )}
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default SettingsPage;
