import {
  IGroupDetails,
  IPaginatedGroupTable,
} from "../common/interfaces/group.interfaces";
import { ADMIN, GROUP, GROUPS, KINDERGARTEN } from "./api.routes";
import { deleteRequest, getRequest, postRequest } from "./http";

export function getAllGroups(
  queryParams?: string
): Promise<IPaginatedGroupTable> {
  return getRequest(`${ADMIN}/${GROUPS}${queryParams ? queryParams : ""}`);
}

export function getGroupDetails(groupId: number): Promise<IGroupDetails> {
  return getRequest(`${ADMIN}/${GROUP}/${groupId}`);
}

export function createOrUpdateGroup(data: any) {
  return postRequest(`${ADMIN}/${GROUP}`, data);
}

export function deleteGroup(groupId: number): Promise<void> {
  return deleteRequest(`${ADMIN}/${GROUP}/${groupId}`);
}

export function getActivitySubtypes() {
  return getRequest(`${KINDERGARTEN}/activity_subtypes`);
}
