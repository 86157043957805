import { FC } from "react";
import "react-phone-input-2/lib/material.css";
import ".././ChildForm.scss";
import { FileIcon, TrashCan } from "../../../svgs/constants";

const UploadedFile: FC<{ name: string; removeImage: () => void }> = ({
  name,
  removeImage,
}) => {
  return (
    <div className="uploaded-file-wrapper">
      <div className="uploaded-file-info">
        <FileIcon />
        {name}
      </div>
      <div onClick={removeImage} style={{ cursor: "pointer" }}>
        <TrashCan />
      </div>
    </div>
  );
};

export default UploadedFile;
