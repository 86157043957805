import { FC, Fragment, useCallback, useEffect, useState } from "react";
import "react-phone-input-2/lib/material.css";
import { PlusCircle, TrashCan } from "../../svgs/constants";
import { InputAdornment, MenuItem, TextField } from "@mui/material";
import {
  IKindergartenDetailsAdditionalActivity,
  ILanguage,
} from "../../common/interfaces/kindergarten.interfaces";
import { LANGUAGE_OPTIONS } from "../../common/constants/common.constants";
import { getLanguageCodeById } from "../../common/utils/helpers";
import {
  updateKindergartenAdditionalActivities,
  updateKindergartenLanguages,
} from "../../api/kindergarten.api";
import { toast } from "react-toastify";
import { DtoFieldStatus } from "../../common/enums/common.enums";
import { UpdateKindergartenAdditionalActivitiesDto } from "../../common/dtos/kindergarten.dto";

const SettingsForm: FC<{
  activities: IKindergartenDetailsAdditionalActivity[];
  languages: ILanguage[];
  handleState: () => void;
}> = ({ activities, languages, handleState }) => {
  const [newLanguages, setNewLanguages] = useState<ILanguage[]>(languages);
  const [newActivities, setNewActivities] = useState<
    IKindergartenDetailsAdditionalActivity[]
  >([]);

  useEffect(() => {
    setNewActivities(Array.from(activities));
  }, []);

  const handleLanguageChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const alreadySelected = newLanguages.find(
      (item) => item.languageId === Number(e.target.value)
    );

    if (alreadySelected) {
      toast.error("You can not assign an already selected language!");
      return;
    }

    const updatedLanguages = [...newLanguages];
    const selectedLanguage = LANGUAGE_OPTIONS.find(
      (lang) => lang.languageId === Number(e.target.value)
    );

    if (selectedLanguage)
      updatedLanguages[index] = {
        ...selectedLanguage,
        name: getLanguageCodeById(selectedLanguage.languageId),
      };
    setNewLanguages([...updatedLanguages]);
  };

  const addLanguage = () => {
    setNewLanguages((newLanguages) => [
      ...newLanguages,
      {
        languageId: -1,
        name: "",
        icon: "",
        isCurrentLanguage: false,
        isDefaultLanguage: false,
      },
    ]);
  };

  const handleActivityChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    setNewActivities((prevState) => {
      prevState[index] = {
        name: e.target.value,
        additionalActivityId: prevState[index].additionalActivityId,
      };
      return [...prevState];
    });
  };

  const addActivity = () => {
    setNewActivities((prevState) => [
      ...prevState,
      { additionalActivityId: -1, name: "" },
    ]);
  };

  const removeActivity = (index: number) => {
    newActivities.splice(index, 1);
    setNewActivities([...newActivities]);
  };

  const removeLanguage = (index: number) => {
    const filteredLanguageArray = newLanguages.filter(
      (language) => index !== language.languageId
    );
    setNewLanguages([...filteredLanguageArray]);
  };

  const getLanguageLabel = useCallback((language: string) => {
    switch (language) {
      case "en":
        return {
          label: LANGUAGE_OPTIONS[0].name,
          imageUrl: LANGUAGE_OPTIONS[0].icon,
        };
      case "nr":
        return {
          label: LANGUAGE_OPTIONS[1].name,
          imageUrl: LANGUAGE_OPTIONS[1].icon,
        };
      case "sr":
        return {
          label: LANGUAGE_OPTIONS[2].name,
          imageUrl: LANGUAGE_OPTIONS[2].icon,
        };
      case "pr":
        return {
          label: LANGUAGE_OPTIONS[3].name,
          imageUrl: LANGUAGE_OPTIONS[3].icon,
        };
      default:
        return {
          label: LANGUAGE_OPTIONS[0].name,
          imageUrl: LANGUAGE_OPTIONS[0].icon,
        };
    }
  }, []);

  const compareToInitialArray = (
    arg: IKindergartenDetailsAdditionalActivity
  ) => {
    const indexInInitialArray = activities.findIndex(
      (item) => item.additionalActivityId === arg.additionalActivityId
    );

    if (indexInInitialArray === -1) {
      return DtoFieldStatus.New;
    } else {
      if (arg.name !== activities[indexInInitialArray].name) {
        return DtoFieldStatus.Updated;
      }
      return DtoFieldStatus.Unaffected;
    }
  };

  const compareFunction = (
    a: UpdateKindergartenAdditionalActivitiesDto,
    b: UpdateKindergartenAdditionalActivitiesDto
  ) => {
    if (a.additionalActivityID === null) {
      return -1;
    } else if (b.additionalActivityID === null) {
      return 1;
    }

    return a.additionalActivityID - b.additionalActivityID;
  };

  const handleSubmitLanguages = async () => {
    const defaultLanguageId = newLanguages[0].languageId;
    const chosenLanguages = newLanguages
      .map((item) => item.languageId)
      .filter((item) => item !== defaultLanguageId);

    try {
      await updateKindergartenLanguages({ defaultLanguageId, chosenLanguages });
      toast.success("Successfully uploaded new languages!");
    } catch (error: any) {
      console.log(error.message);
      toast.error("Failed to save new languages");
    }
  };

  const handleSubmitActivities = async () => {
    for (const item of newActivities) {
      if (item.name === "") {
        toast.error("Activity name can not be empty!");
        return;
      }
    }

    const dtoActivities = newActivities.map((item) => ({
      additionalActivityID:
        item.additionalActivityId === -1 ? null : item.additionalActivityId,
      name: item.name,
      status: compareToInitialArray(item),
    }));

    const sortedActivities = dtoActivities.sort(compareFunction);

    const request = { additionalActivities: sortedActivities };

    try {
      await updateKindergartenAdditionalActivities(request);
      toast.success("Successfully uploaded new activities!");
    } catch (error: any) {
      console.log(error.message);
      toast.error("Failed to save new activities");
    }
  };

  return (
    <div className="section-wrapper">
      <div className="section-container">
        <h3>Languages</h3>
        <div className="input-section">
          {newLanguages.map((item, index) => (
            <Fragment key={index}>
              <div className="delete-option-container">
                <h4>{!index ? "Default language" : `Language ${index + 1}`}</h4>
                {index && (
                  <div onClick={() => removeLanguage(item.languageId)}>
                    <TrashCan />
                  </div>
                )}
              </div>
              <TextField
                label="Language"
                select
                value={item.languageId}
                onChange={(e: any) => handleLanguageChange(e, index)}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {/* TODO: Check condition here */}
                      {item.name !== "" && (
                        <img
                          src={getLanguageLabel(item.name)?.imageUrl}
                          className="language-icon"
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
              >
                {LANGUAGE_OPTIONS.map((item) => {
                  return (
                    <MenuItem value={item.languageId} key={item.languageId}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Fragment>
          ))}
          {newLanguages.length < LANGUAGE_OPTIONS.length && (
            <div onClick={addLanguage} className="add-parent">
              <PlusCircle />
              Add language
            </div>
          )}
        </div>
      </div>
      <div className="form-actions">
        <div className="cancel-action" onClick={handleState}>
          Cancel
        </div>
        <div className="submit-action" onClick={handleSubmitLanguages}>
          Edit languages
        </div>
      </div>
      <div className="section-container">
        <h3>Other activities</h3>
        <div className="input-section">
          {newActivities.map((item, index) => (
            <Fragment key={index}>
              <div className="delete-option-container">
                <h4>{`Activity ${index + 1}`}</h4>
                {index >= activities.length && (
                  <div
                    onClick={() => removeActivity(index)}
                    style={{ cursor: "pointer" }}
                  >
                    <TrashCan />
                  </div>
                )}
              </div>
              <TextField
                label="Activity Name"
                value={item.name}
                onChange={(e: any) => handleActivityChange(e, index)}
                fullWidth
              />
            </Fragment>
          ))}
          <div onClick={addActivity} className="add-parent">
            <PlusCircle />
            Add another activity
          </div>
        </div>
      </div>
      <div className="form-actions">
        <div className="cancel-action" onClick={handleState}>
          Cancel
        </div>
        <div className="submit-action" onClick={handleSubmitActivities}>
          Edit activities
        </div>
      </div>
    </div>
  );
};

export default SettingsForm;
