import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@mui/material";
import React from "react";
import { IApiActivitySubtypes } from "../../common/interfaces/group.interfaces";
import { capitalizeFirstLetter } from "../../common/utils/helpers";

const GroupActivities: React.FC<{
  activities: IApiActivitySubtypes[];
  activityOptions: any[];
  setActivities: React.Dispatch<React.SetStateAction<IApiActivitySubtypes[]>>;
}> = ({ activities, setActivities, activityOptions }) => {
  const handleActivityChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    entityClass: string
  ) => {
    const { name } = event.target;

    const formattedEntityClass = capitalizeFirstLetter(entityClass);

    const currentEntityClassState = activities.find(
      (obj) => obj.entityClass === formattedEntityClass
    );

    if (currentEntityClassState) {
      let currentArray = [...currentEntityClassState.items];

      const existingItem = currentArray.find(
        (item) => item.activityTypeId === Number(event.target.value)
      );

      if (existingItem) {
        currentArray = currentArray.filter(
          (item) => item.activityTypeId !== Number(event.target.value)
        );
        setActivities((prevState) => [
          ...prevState.filter(
            (item) => item.entityClass !== formattedEntityClass
          ),
          { entityClass: formattedEntityClass, items: currentArray },
        ]);
      } else {
        currentArray.push({
          activityTypeId: Number(event.target.value),
          alphaNumericCode: "",
          description: "",
          typeIcon: "",
          entityClass: formattedEntityClass,
          meaning: name,
        });
        setActivities((prevState) => [
          ...prevState.filter(
            (item) => item.entityClass !== formattedEntityClass
          ),
          { entityClass: formattedEntityClass, items: currentArray },
        ]);
      }
    } else {
      setActivities((prevState) => [
        ...prevState,
        {
          entityClass: formattedEntityClass,
          items: [
            {
              typeIcon: "",
              activityTypeId: Number(event.target.value),
              meaning: name,
              entityClass: formattedEntityClass,
              alphaNumericCode: "",
              description: "",
            },
          ],
        },
      ]);
    }
  };

  const checkIfSelected = (itemToCheck: any, entityClass: string) => {
    let checked = false;

    const formattedEntityClass = capitalizeFirstLetter(entityClass);

    const existingObj = activities.find(
      (item) => item.entityClass === formattedEntityClass
    );

    if (existingObj) {
      const existingItem = existingObj.items.find(
        (item) => item.activityTypeId === itemToCheck.activityTypeId
      );
      if (existingItem) checked = true;
    }
    return checked;
  };

  return (
    <div className="section-container">
      <h3>Activities</h3>
      <div className="input-section">
        {activityOptions.map((item: any, index: number) => (
          <FormControl className="checkbox-selection" key={index}>
            <FormLabel style={{ textTransform: "capitalize" }}>
              {item.entityClass}
            </FormLabel>
            <FormGroup row>
              {item.items.map((subItem: any, index: number) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      name={subItem.meaning}
                      value={subItem.activityTypeId}
                      style={{
                        color: "#3F3273",
                      }}
                      checked={checkIfSelected(subItem, item.entityClass)}
                      onChange={(e: any) =>
                        handleActivityChange(e, item.entityClass)
                      }
                    />
                  }
                  label={subItem.meaning}
                />
              ))}
            </FormGroup>
          </FormControl>
        ))}
      </div>
    </div>
  );
};

export default GroupActivities;
