import SettingsMenu from "../../components/SettingsMenu/SettingsMenu";
import { FC, useEffect, useState } from "react";
import "./OverviewPage.scss";
import { OverviewRow } from "../../components/DetailsTableComponents/DetailsTableComponents";
import DetailsPageHeader from "../../components/DetailsPageHeader/DetailsPageHeader";
import OverviewForm from "../../components/OverviewForm/OverviewForm";
import { getKindergartenInfo } from "../../api/kindergarten.api";
import { IKindergartenInfo } from "../../common/interfaces/kindergarten.interfaces";
import { ReturnSection } from "../../components/FormActions/FormActions";
import Loader from "../../components/Loader/Loader";

const OverviewPage: FC<{}> = () => {
  const [editing, toggleEditing] = useState<boolean>(false);
  const [kindergartenInfo, setKindergartenInfo] = useState<IKindergartenInfo>();

  useEffect(() => {
    if (!editing) fetchKindergartenData();
  }, [editing]);

  useEffect(() => {
    if (kindergartenInfo) {
      localStorage.setItem(
        "kindergartenBasicInfo",
        JSON.stringify({
          name: kindergartenInfo.name,
          address: kindergartenInfo.address,
        })
      );
    }
  }, [kindergartenInfo]);

  const fetchKindergartenData = async () => {
    try {
      const response = await getKindergartenInfo();
      setKindergartenInfo(response);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const handleToggleEdit = () => {
    toggleEditing(!editing);
  };

  return (
    <div className="padding-wrapper">
      <SettingsMenu />
      {kindergartenInfo ? (
        <>
          {!editing ? (
            <>
              <DetailsPageHeader
                title={kindergartenInfo.name}
                imgUrl={kindergartenInfo.kindergartenLogo}
                handleState={handleToggleEdit}
              />
              <div className="details-page-layout">
                <div className="details-page-section" style={{ width: "30%" }}>
                  <div className="details-page-section-header">
                    Basic Details
                  </div>
                  <OverviewRow title="Name" value={kindergartenInfo.name} />
                  <OverviewRow
                    title="Address"
                    value={kindergartenInfo.address}
                  />
                  <OverviewRow title="PIB" value={kindergartenInfo.PIB} />
                  <OverviewRow
                    title="Company Registration Number"
                    value={kindergartenInfo.companyRegistrationNumber}
                  />
                  <OverviewRow
                    title="Representative"
                    value={kindergartenInfo.representativeFullName}
                  />
                </div>
                <div className="details-page-section">
                  <div className="details-page-section-header">
                    Contact Information
                  </div>
                  <OverviewRow
                    title="Contact Person"
                    value={kindergartenInfo.contactPersonFullName}
                  />
                  <OverviewRow title="Email" value={kindergartenInfo.email} />
                  {kindergartenInfo.phoneNumbers.map((item, index) => (
                    <OverviewRow
                      title={`Phone Number ${index + 1}`}
                      value={item}
                      key={index}
                    />
                  ))}
                </div>
              </div>
            </>
          ) : (
            <>
              <ReturnSection handleToggleEdit={handleToggleEdit} />
              <OverviewForm
                kindergartenId={kindergartenInfo.kindergartenId}
                name={kindergartenInfo.name}
                address={kindergartenInfo.address}
                PIB={kindergartenInfo.PIB}
                companyRegistrationNumber={kindergartenInfo.companyRegistrationNumber.toString()}
                contactPersonFullName={kindergartenInfo.contactPersonFullName}
                email={kindergartenInfo.email}
                phoneNumbers={kindergartenInfo.phoneNumbers}
                representativeFullName={kindergartenInfo.representativeFullName}
                kLogo={kindergartenInfo.kindergartenLogo}
                handleToggleEdit={handleToggleEdit}
              />
            </>
          )}
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default OverviewPage;
