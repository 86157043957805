import { FC, useEffect, useState } from "react";
import "./CustomDropdown.scss";
import { Box, MenuItem, TextField } from "@mui/material";
import { IChildrenFormParents } from "../../common/interfaces/children.interface";

// TODO: Implement search and header to the dropdown

const CustomDropdown: FC<{
  label: string;
  chosenOption: number | undefined;
  setChosenOption: (e: any, index: number) => void;
  index?: number;
  availableParents?: IChildrenFormParents[];
}> = ({ label, chosenOption, setChosenOption, index, availableParents }) => {
  const [filteredOptions, setFilteredOptions] = useState<
    IChildrenFormParents[]
  >([]);

  useEffect(() => {
    setFilteredOptions(availableParents ?? []);
  }, [availableParents]);

  const handleChange = (event: any) => {
    setChosenOption(event, index ?? 0);
  };

  return (
    <Box>
      <TextField
        label={label}
        select
        value={chosenOption}
        onChange={handleChange}
        fullWidth
      >
        {filteredOptions.map((item) => (
          <MenuItem value={item.parentId} key={item.parentId}>
            <DropdownOption id={item.parentId} name={item.name} />
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
};

const DropdownOption: FC<{ name: string; id: number }> = ({ id, name }) => {
  return (
    <div className="child-option">
      <div className="child-data">{name}</div>
    </div>
  );
};

export default CustomDropdown;
