import { FC } from "react";
import { useNavigate } from "react-router";
import { PencilIcon } from "../../svgs/constants";
import backArrow from "../../assets/arrow_back.svg";
import { getInitials } from "../../common/utils/helpers";

export const DetailsPageHeader: FC<{
  title: string;
  subtext?: string;
  backRoute?: string;
  backTitle?: string;
  imgUrl?: string;
  initials?: boolean;
  handleState: () => void;
}> = ({
  title,
  backRoute,
  backTitle,
  subtext,
  imgUrl,
  initials,
  handleState,
}) => {
  const navigate = useNavigate();

  return (
    <div>
      {backRoute && (
        <div className="back-container" onClick={() => navigate(backRoute)}>
          <img src={backArrow} alt="Back arrow" />
          {backTitle}
        </div>
      )}
      <div
        className="details-page-header"
        style={{ marginTop: `${backRoute ? "54px" : ""}` }}
      >
        <div className="details-page-header-wrapper">
          {imgUrl && <img src={imgUrl} alt="kindergarten logo" />}
          {initials && (
            <div className="initials-circle">{getInitials(title)}</div>
          )}
          <div className="details-person-info-wrapper">
            <h1>{title}</h1>
            <p>{subtext}</p>
          </div>
        </div>
        <div className="edit-container" onClick={handleState}>
          <p>Edit</p>
          <PencilIcon />
        </div>
      </div>
    </div>
  );
};

export default DetailsPageHeader;
