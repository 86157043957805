import { TextField } from "@mui/material";
import { ChangeEvent, FC, MutableRefObject } from "react";
import "react-phone-input-2/lib/material.css";
import { FileIcon } from "../../svgs/constants";
import { MAX_IMAGE_SIZE } from "../../common/constants/common.constants";
import { useDropzone } from "react-dropzone";
import UploadedFile from "../ChildForm/ProfilePictureSection/ProfilePictureSection";

const OverviewBasicDetails: FC<{
  nameRef: MutableRefObject<HTMLInputElement | undefined>;
  addressRef: MutableRefObject<HTMLInputElement | undefined>;
  pibRef: MutableRefObject<HTMLInputElement | undefined>;
  regNumberRef: MutableRefObject<HTMLInputElement | undefined>;
  representativeRef: MutableRefObject<HTMLInputElement | undefined>;
  file: File | undefined;
  errors: string[];
  changeRefValue: (
    event: ChangeEvent<HTMLInputElement>,
    customRef: any,
    fieldName?: string
  ) => void;
  setFile: React.Dispatch<React.SetStateAction<File | undefined>>;
}> = ({
  nameRef,
  addressRef,
  pibRef,
  regNumberRef,
  representativeRef,
  file,
  errors,
  changeRefValue,
  setFile,
}) => {
  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    maxSize: MAX_IMAGE_SIZE,
    onDrop: async (acceptedFiles) => {
      setFile(acceptedFiles[0]);
    },
  });

  const removeImage = () => {
    setFile(undefined);
  };

  return (
    <div className="section-container">
      <h3>Basic Details</h3>
      <div className="input-section">
        <div>
          <TextField
            inputRef={nameRef}
            label="Name *"
            size="medium"
            onInput={(e: ChangeEvent<HTMLInputElement>) =>
              changeRefValue(e, nameRef, "name")
            }
            sx={{ width: "100%" }}
          />
          {errors.includes("name") && (
            <p className="error-text">Name can not be empty</p>
          )}
        </div>
        <div>
          <TextField
            inputRef={addressRef}
            label="Address *"
            size="medium"
            onInput={(e: ChangeEvent<HTMLInputElement>) =>
              changeRefValue(e, addressRef, "address")
            }
            sx={{ width: "100%" }}
          />
          {errors.includes("address") && (
            <p className="error-text">Address can not be empty</p>
          )}
        </div>
        <div>
          <TextField
            inputRef={pibRef}
            label="PIB *"
            size="medium"
            onInput={(e: ChangeEvent<HTMLInputElement>) =>
              changeRefValue(e, pibRef, "PIB")
            }
            sx={{ width: "100%" }}
          />
          {errors.includes("PIB") && (
            <p className="error-text">PIB can not be empty</p>
          )}
        </div>
        <div>
          <TextField
            inputRef={regNumberRef}
            label="Company registration number *"
            size="medium"
            onInput={(e: ChangeEvent<HTMLInputElement>) =>
              changeRefValue(e, regNumberRef, "companyRegistrationNumber")
            }
            sx={{ width: "100%" }}
          />
          {errors.includes("companyRegistrationNumber") && (
            <p className="error-text">Registration number can not be empty</p>
          )}
        </div>
        <div>
          <TextField
            inputRef={representativeRef}
            label="Representative *"
            size="medium"
            onInput={(e: ChangeEvent<HTMLInputElement>) =>
              changeRefValue(e, representativeRef, "representativeFullName")
            }
            sx={{ width: "100%" }}
          />
          {errors.includes("representativeFullName") && (
            <p className="error-text">Representative can not be empty</p>
          )}
        </div>
        <div>
          <h4>Logo</h4>
          <div {...getRootProps()} className="upload-image-container">
            <FileIcon />
            <p className="upload-main-text">
              <input {...getInputProps()} />
              <span onClick={open}>Click to upload</span> or drag and drop
            </p>
            <p className="upload-side-text">SVG, PNG, JPG (max. 3MB)</p>
          </div>
          {file !== undefined && (
            <UploadedFile
              name={file.name ?? "Current logo"}
              removeImage={removeImage}
            ></UploadedFile>
          )}
        </div>
      </div>
    </div>
  );
};

export default OverviewBasicDetails;
