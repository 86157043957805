import gbSquare from "../../assets/gbFlagSquare.png";
import rsSquare from "../../assets/serbiaFlagSquare.png";
import prSquare from "../../assets/portugalFlagSquare.png";
import nwSquare from "../../assets/norwayFlagSquare.png";
import { ILanguage } from "../interfaces/kindergarten.interfaces";

export const LANGUAGE_OPTIONS: ILanguage[] = [
  {
    languageId: 1,
    name: "English",
    icon: gbSquare,
    isCurrentLanguage: false,
    isDefaultLanguage: false,
  },
  {
    languageId: 2,
    name: "Norwegian",
    icon: nwSquare,
    isCurrentLanguage: false,
    isDefaultLanguage: false,
  },
  {
    languageId: 3,
    name: "Serbian",
    icon: rsSquare,
    isCurrentLanguage: false,
    isDefaultLanguage: false,
  },

  {
    languageId: 4,
    name: "Portuguese",
    icon: prSquare,
    isCurrentLanguage: false,
    isDefaultLanguage: false,
  },
];

export const COLOR_LIST = [
  "yellow",
  "pistachio",
  "purple",
  "sky",
  "orange",
  "lavander",
  "watermelon",
  "red",
  "sea",
];

export const MAX_IMAGE_SIZE = 3000000;

export const ITEMS_PER_PAGE = 10;

export const NUMBER_REGEX: RegExp = /^\d+$/;
