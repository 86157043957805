import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import "react-phone-input-2/lib/material.css";
import { useNavigate } from "react-router";
import { CHILDREN } from "../../common/constants/routes.constants";
import "./ChildForm.scss";
import { DtoFieldStatus, Relationship } from "../../common/enums/common.enums";
import ChildBasicInfoSection from "./ChildBasicInfoSection/ChildBasicInfoSection";
import { PlusCircle } from "../../svgs/constants";
import FormActions, { DataManagement } from "../FormActions/FormActions";
import ConfirmActionModal from "../Modal/ConfirmDeletionModal";
import { createOrUpdateChildren, deleteChild } from "../../api/children.api";
import {
  IChildrenFormParents,
  IChildrenGroup,
  IChildrenParents,
} from "../../common/interfaces/children.interface";
import { getAllParents } from "../../api/parents.api";
import CustomParentsDrodown from "../CustomDropdown/CustomParentsDrodown";
import { getAllGroups } from "../../api/groups.api";
import dayjs from "dayjs";
import {
  CreateOrUpdateChildDto,
  CreateOrUpdateChildInfo,
} from "../../common/dtos/children.dto";
import {
  removeWordFromArray,
  validateObject,
} from "../../common/utils/helpers";
import { Delete } from "@mui/icons-material";
import { toast } from "react-toastify";

const ChildForm: FC<{
  edit?: boolean;
  childId?: number;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
  gender?: string;
  address?: string;
  group?: IChildrenGroup;
  parents?: IChildrenParents[];
  childAvatar?: any;
  handleToggleEdit?: () => void;
}> = ({
  edit,
  childId,
  firstName,
  lastName,
  dateOfBirth,
  address,
  gender,
  group,
  parents,
  childAvatar,
  handleToggleEdit,
}) => {
  const [selectedGender, setSelectedGender] = useState("");
  const [selectedGroup, setSelectedGroup] = useState<IChildrenGroup>();
  const [availableGroupOptions, setAvailableGroupOptions] = useState<any[]>([]);
  const [availableParents, setAvailableParents] = useState<
    IChildrenFormParents[]
  >([]);
  const [selectedParents, setSelectedParents] = useState<IChildrenParents[]>(
    []
  );

  const [file, setFile] = useState<File | undefined>();
  const [showDeletionModal, toggleShowDeletionModal] = useState<boolean>();
  const [chosenDateOfBirth, setChosenDateOfBirth] =
    useState<dayjs.Dayjs | null>(null);
  const [initialParents, setInitialParents] = useState<IChildrenParents[]>([]);
  const [errors, setErrors] = useState<string[]>([]);

  const firstNameRef = useRef<HTMLInputElement>();
  const lastNameRef = useRef<HTMLInputElement>();
  const addressRef = useRef<HTMLInputElement>();

  const navigate = useNavigate();

  useEffect(() => {
    if (parents) setInitialParents(Array.from(parents));
  }, []);

  const handleSetPrimaryChange = (item: IChildrenParents) => {
    if (item.parentId === -1) return;

    setSelectedParents((prevState) => {
      const foundParent = prevState.find(
        (parent) => parent.parentId === item.parentId
      );
      if (foundParent) {
        foundParent.isPrimaryCarer = !foundParent?.isPrimaryCarer;
      }
      return [...prevState];
    });
  };

  const addParent = () => {
    const newParents = {
      parentId: -1,
      parentRelation: "",
      isPrimaryCarer: false,
    };
    setSelectedParents((prevState) => [...prevState, newParents]);
  };

  const populateFields = () => {
    if (dateOfBirth) {
      const dateFromString = new Date(dateOfBirth);
      const dayJsDate = dayjs(dateFromString);
      setChosenDateOfBirth(dayJsDate);
    }
    if (firstNameRef.current) firstNameRef.current.value = firstName ?? "";
    if (lastNameRef.current) lastNameRef.current.value = lastName ?? "";
    setSelectedGender(gender ?? "");
    if (addressRef.current) addressRef.current.value = address || "";
    setSelectedGroup(group ?? { groupId: -1, groupName: "" });
    const mappedChosenParents = parents?.map((item) => ({
      parentId: item.parentId,
      parentRelation: item.parentRelation,
      isPrimaryCarer: item.isPrimaryCarer,
    }));
    setSelectedParents(mappedChosenParents ?? []);
    if (childAvatar) setFile(childAvatar);
  };

  useEffect(() => {
    if (edit) {
      populateFields();
    }
  }, []);

  const handleSubmit = async () => {
    let valid = true;
    if (selectedGroup?.groupId === undefined) {
      toast.error("Select a group for the child");
      valid = false;
    }

    for (const parent of selectedParents) {
      if (parent.parentId === -1 || !parent.parentRelation) {
        toast.error("Fill out parent data");
        valid = false;
      }
    }

    const dtoParents = selectedParents.map((item) => ({
      parentId: item.parentId,
      parentRelation: item.parentRelation?.toLowerCase(),
      isPrimaryCarer: item.isPrimaryCarer,
      status: compareToInitialArray(item),
    }));

    const dtoParentsWithDeleted = addDeletedParents(dtoParents);

    const childrenData: CreateOrUpdateChildDto = {
      childInfo: {
        childId: childId ?? null,
        name: firstNameRef.current?.value!,
        surname: lastNameRef.current?.value!,
        birthDate: chosenDateOfBirth
          ? chosenDateOfBirth.toISOString().slice(0, 10)
          : "",
        address: addressRef.current?.value!,
        gender: selectedGender,
        groupId: selectedGroup?.groupId,
        parents: dtoParentsWithDeleted,
      },
      logo: file ?? null,
    };

    if (!validateChildrenForm(childrenData.childInfo)) valid = false;

    if (!valid) return;

    try {
      await createOrUpdateChildren(childrenData);
      toast.success("Successfully updloaded child data!");
      if (edit && handleToggleEdit) {
        handleToggleEdit();
      } else {
        navigate(CHILDREN);
      }
    } catch (error: any) {
      const apiMessage = error?.response?.data?.error?.message;
      toast.error(
        apiMessage ?? "Something went wrong while saving child data!"
      );
    }
  };

  const compareToInitialArray = (arg: IChildrenParents) => {
    const indexInInitialArray = initialParents.findIndex(
      (item) => item.parentId === arg.parentId
    );

    if (indexInInitialArray === -1) {
      return DtoFieldStatus.New;
    } else {
      if (checkParentForChange(arg)) {
        return DtoFieldStatus.Updated;
      }
      return DtoFieldStatus.Unaffected;
    }
  };

  const addDeletedParents = (dtoParents: any[]) => {
    for (const originalParent of initialParents) {
      if (
        dtoParents.findIndex(
          (item) => item.parentId === originalParent.parentId
        ) === -1
      ) {
        dtoParents = [
          ...dtoParents,
          {
            parentId: originalParent.parentId,
            parentRelation: originalParent.parentRelation?.toLowerCase(),
            isPrimaryCarer: originalParent.isPrimaryCarer,
            status: DtoFieldStatus.Deleted,
          },
        ];
      }
    }

    return dtoParents;
  };

  const checkParentForChange = (parent: IChildrenParents) => {
    let hasChanged = false;

    const initialParent = initialParents.find(
      (item) => item.parentId === parent.parentId
    );

    if (parent.parentRelation !== initialParent?.parentRelation) {
      hasChanged = true;
    }

    if (parent.isPrimaryCarer !== initialParent?.isPrimaryCarer) {
      hasChanged = true;
    }

    return hasChanged;
  };

  const changeRefValue = (
    event: ChangeEvent<HTMLInputElement>,
    customRef: any,
    fieldName?: string
  ) => {
    setErrors((errors) => [...removeWordFromArray(fieldName!, errors)]);
    if (customRef.current) {
      customRef.current.value = event.target.value;
    }
  };

  useEffect(() => {
    setErrors((errors) => [...removeWordFromArray("gender", errors)]);
  }, [selectedGender]);

  const handleGroupChange = (e: any) => {
    const chosenGroup = availableGroupOptions.find(
      (item) => item.groupId === e.target.value
    );
    setSelectedGroup(chosenGroup);
  };

  const handleRelationChange = (e: any, index: number) => {
    const updatedParents = [...selectedParents];
    updatedParents[index].parentRelation = e.target.value;
    setSelectedParents(updatedParents);
  };

  const handleDeleteParent = (index: number) => {
    setSelectedParents((prevState) => {
      const updatedParents = [...prevState];
      updatedParents.splice(index, 1);
      return updatedParents;
    });
  };

  const handleParentChange = (e: any, index: number) => {
    const existingParent = selectedParents.find(
      (item) => item.parentId === Number(e.target.value)
    );

    if (existingParent) {
      toast.error("Selected parent is already chosen");
      return;
    }

    const updatedParents = [...selectedParents];
    updatedParents[index].parentId = e.target.value;
    setSelectedParents(updatedParents);
  };

  const handleFormCancel = () => {
    if (edit && handleToggleEdit) {
      handleToggleEdit();
    } else navigate(CHILDREN);
  };

  const handleChildDelete = async () => {
    if (edit && childId) {
      try {
        await deleteChild(childId);
        toast.success("Successfully deleted child!");
        navigate(CHILDREN);
      } catch (error: any) {
        const apiMessage = error?.response?.data?.error?.message;
        toast.error(
          apiMessage ?? "Something went wrong while deleting child data!"
        );
      }
    }
  };

  const handleDateChange = (date: dayjs.Dayjs | null) => {
    setErrors((errors) => [...removeWordFromArray("birthDate", errors)]);
    setChosenDateOfBirth(date);
  };

  const validateChildrenForm = (childData: CreateOrUpdateChildInfo) => {
    let isValid = true;
    setErrors([]);
    const { errors, valid } = validateObject(childData);
    isValid = valid;
    setErrors((prevState) => [...prevState, ...errors]);
    return isValid;
  };

  const fetchData = async () => {
    try {
      const parentsResponse = await getAllParents(
        "?page=1&paginationItemsPerPage=500"
      );
      const mappedResponseParents = parentsResponse.data.map((item) => ({
        parentId: item.parentId,
        name: item.name + " " + item.surname,
        surname: item.surname,
      }));
      setAvailableParents(mappedResponseParents);
      const groupsResponse = await getAllGroups(
        "?page=1&paginationItemsPerPage=500"
      );
      const mappedResponseGroups = groupsResponse.data.map((item) => ({
        groupId: item.adminGroupId,
        groupName: item.adminGroupName,
      }));
      setAvailableGroupOptions(mappedResponseGroups);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {showDeletionModal && (
        <ConfirmActionModal
          closeModal={() => toggleShowDeletionModal(false)}
          confirmedAction={handleChildDelete}
        />
      )}
      <div className="section-wrapper">
        <ChildBasicInfoSection
          firstNameRef={firstNameRef}
          lastNameRef={lastNameRef}
          changeRefValue={changeRefValue}
          addressRef={addressRef}
          chosenDateOfBirth={chosenDateOfBirth}
          gender={selectedGender}
          setGender={setSelectedGender}
          file={file}
          setFile={setFile}
          onDateChange={handleDateChange}
          errors={errors}
        />
        <div className="section-container">
          <h3>Group</h3>
          <div className="input-section">
            {(edit && selectedGroup) || !edit ? (
              <TextField
                label="Choose a group"
                select
                defaultValue={selectedGroup ? selectedGroup.groupId : ""}
                onChange={(e: any) => handleGroupChange(e)}
                fullWidth
              >
                {availableGroupOptions.map((item) => (
                  <MenuItem key={item.groupId} value={item.groupId}>
                    {item.groupName}
                  </MenuItem>
                ))}
              </TextField>
            ) : null}
            {errors.includes("group") && (
              <p className="error-text">Group must be selected</p>
            )}
          </div>
        </div>
        <div className="section-container">
          <h3>Parents</h3>
          <div className="input-section">
            {selectedParents.map((item, index) => (
              <Stack
                key={item.parentId}
                direction="row"
                className="add-another-entity-row"
                alignItems="center"
              >
                <Stack direction="column" className="input-section-fields">
                  <h4>Parent {index + 1}</h4>
                  <CustomParentsDrodown
                    label="Choose a parent"
                    chosenOption={item.parentId}
                    setChosenOption={(e: any) => handleParentChange(e, index)}
                    availableParents={availableParents}
                  />

                  <TextField
                    label="Choose a relation"
                    select
                    disabled={item.parentId === -1}
                    value={item.parentRelation}
                    onChange={(e: any) => handleRelationChange(e, index)}
                    fullWidth
                  >
                    {Object.values(Relationship).map((item, index) => (
                      <MenuItem value={item.toLowerCase()} key={index}>
                        {item}
                      </MenuItem>
                    ))}
                  </TextField>
                  <FormControlLabel
                    sx={{ color: "#626262" }}
                    control={
                      <Checkbox
                        style={{ color: "#3f3273" }}
                        checked={
                          item.isPrimaryCarer !== undefined &&
                          item.isPrimaryCarer
                        }
                        onChange={() => handleSetPrimaryChange(item)}
                      />
                    }
                    label="Primary carer"
                  />
                </Stack>
                <Delete
                  className="delete-entity-row"
                  onClick={() => handleDeleteParent(index)}
                />
              </Stack>
            ))}
            <div onClick={addParent} className="add-parent">
              <PlusCircle />
              Add another parent
            </div>
          </div>
        </div>
        {edit && (
          <DataManagement openModal={() => toggleShowDeletionModal(true)} />
        )}
        <FormActions
          edit={edit}
          forwardAction={handleSubmit}
          backAction={handleFormCancel}
        />
      </div>
    </>
  );
};

export default ChildForm;
