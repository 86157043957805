import SettingsMenu from "../../components/SettingsMenu/SettingsMenu";
import backArrow from "../../assets/arrow_back.svg";
import "./ParentFormPage.scss";
import { FC } from "react";
import ParentForm from "../../components/ParentForm/ParentForm";
import { useNavigate, useParams } from "react-router";
import { PARENTS } from "../../common/constants/routes.constants";

const ParentFormPage: FC<{ edit?: boolean }> = ({ edit }) => {
  const navigate = useNavigate();

  return (
    <div className="padding-wrapper">
      <SettingsMenu />
      <div className="back-container" onClick={() => navigate(PARENTS)}>
        <img src={backArrow} alt="Back arrow" />
        Parents
      </div>
      <h1>{edit ? "Edit parent" : "Create a new parent"}</h1>
      <ParentForm edit={edit} />
    </div>
  );
};

export default ParentFormPage;
