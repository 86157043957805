import "react-phone-input-2/lib/material.css";
import { TEACHERS } from "../../common/constants/routes.constants";
import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import "react-phone-input-2/lib/material.css";
import { useNavigate } from "react-router";
import TeacherBasicInfoSection from "./TeacherBasicInfoSection/TeacherBasicInfoSection";
import FormActions, { DataManagement } from "../FormActions/FormActions";
import ConfirmActionModal from "../Modal/ConfirmDeletionModal";
import { createOrUpdateTeacher, deleteTeacher } from "../../api/teachers.api";
import CustomSelectDropdown from "../CustomDropdown/CustomSelectDropdown";
import {
  ITeacherFormGroups,
  ITeachersGroup,
} from "../../common/interfaces/teacher.interface";
import { getAllGroups } from "../../api/groups.api";
import {
  CreateOrUpdateTeacherDto,
  CreateOrUpdateTeacherGroupsDto,
} from "../../common/dtos/teacher.dto";
import { DtoFieldStatus } from "../../common/enums/common.enums";
import { toast } from "react-toastify";
import {
  removeWordFromArray,
  validateObject,
} from "../../common/utils/helpers";

const TeacherForm: FC<{
  edit?: boolean;
  teacherId?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  phNumber?: string;
  gender?: string;
  address?: string;
  groups?: ITeachersGroup[];
  handleToggleEdit?: () => void;
}> = ({
  edit,
  teacherId,
  firstName,
  lastName,
  email,
  address,
  phNumber,
  gender,
  groups,
  handleToggleEdit,
}) => {
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [chosenGender, setChosenGender] = useState<string>("");
  const [chosenGroups, setChosenGroups] = useState<ITeacherFormGroups[]>([]);
  const [showDeletionModal, toggleShowDeletionModal] = useState<boolean>();
  const [availableGroupOptions, setAvailableGroupOptions] = useState<
    ITeacherFormGroups[]
  >([]);
  const [initialGroups, setInitialGroups] = useState([...(groups ?? [])]);
  const [errors, setErrors] = useState<string[]>([]);

  const firstNameRef = useRef<HTMLInputElement>();
  const lastNameRef = useRef<HTMLInputElement>();
  const emailRef = useRef<HTMLInputElement>();
  const addressRef = useRef<HTMLInputElement>();

  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await getAllGroups("?page=1&paginationItemsPerPage=500");
      const mappedResponse = response.data.map((item) => ({
        id: item.adminGroupId,
        groupName: item.adminGroupName,
      }));
      setAvailableGroupOptions(mappedResponse);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (edit) {
      populateFields();
    }
  }, []);

  useEffect(() => {
    setErrors((errors) => [...removeWordFromArray("gender", errors)]);
  }, [chosenGender]);

  useEffect(() => {
    setErrors((errors) => [...removeWordFromArray("phone", errors)]);
  }, [phoneNumber]);

  const compareToInitialArray = (arg: ITeacherFormGroups) => {
    const indexInInitialArray = initialGroups.findIndex(
      (item) => item.groupId === arg.id
    );

    if (indexInInitialArray === -1) {
      return DtoFieldStatus.New;
    } else {
      return DtoFieldStatus.Unaffected;
    }
  };

  const addDeletedGroupsToDto = (
    dtoGroups: CreateOrUpdateTeacherGroupsDto[]
  ) => {
    for (const originalItem of initialGroups) {
      if (
        dtoGroups.findIndex((item) => item.groupID === originalItem.groupId) ===
        -1
      ) {
        dtoGroups = [
          ...dtoGroups,
          {
            groupID: originalItem.groupId,
            groupStatus: DtoFieldStatus.Deleted,
            isPrimary: false,
          },
        ];
      }
    }

    return dtoGroups;
  };

  const handleSubmit = async () => {
    const dtoGroups = chosenGroups.map((item) => ({
      groupID: item.id,
      groupStatus: compareToInitialArray(item),
      isPrimary: false,
    }));

    const dtoGroupsWithDeleted = addDeletedGroupsToDto(dtoGroups);

    const teacherData: CreateOrUpdateTeacherDto = {
      teacherID: teacherId ?? null,
      name: firstNameRef.current?.value!,
      surname: lastNameRef.current?.value!,
      address: addressRef.current?.value!,
      email: emailRef.current?.value!,
      gender: chosenGender,
      groups: dtoGroupsWithDeleted,
      phone: phoneNumber,
    };

    if (!validateTeacherForm(teacherData)) return;

    try {
      await createOrUpdateTeacher(teacherData);
      toast.success("Successfully uploaded teacher data!");
      if (edit && handleToggleEdit) {
        handleToggleEdit();
      } else {
        navigate(TEACHERS);
      }
    } catch (error: any) {
      const apiMessage = error?.response?.data?.error?.message;
      toast.error(
        apiMessage ?? "Something went wrong while saving teacher data!"
      );
    }
  };

  const changeRefValue = (
    event: ChangeEvent<HTMLInputElement>,
    customRef: any,
    fieldName?: string
  ) => {
    setErrors((errors) => [...removeWordFromArray(fieldName!, errors)]);
    if (customRef.current) {
      customRef.current.value = event.target.value;
    }
  };

  const handleFormCancel = () => {
    if (edit && handleToggleEdit) {
      handleToggleEdit();
    } else navigate(TEACHERS);
  };

  const populateFields = () => {
    if (firstNameRef.current) firstNameRef.current.value = firstName ?? "";
    if (lastNameRef.current) lastNameRef.current.value = lastName ?? "";
    if (emailRef.current) emailRef.current.value = email ?? "";
    if (addressRef.current) addressRef.current.value = address ?? "";
    setPhoneNumber(phNumber ?? "");
    setChosenGender(gender ?? "");
    const mappedChosenGroups = groups?.map((item) => ({
      id: item.groupId,
      groupName: item.groupName,
    }));
    setChosenGroups(mappedChosenGroups ?? []);
  };

  const handleGroupsChange = (groups: ITeacherFormGroups[]) => {
    setChosenGroups([...groups]);
  };

  const handleTeacherDelete = async () => {
    if (edit && teacherId) {
      try {
        await deleteTeacher(teacherId);
        toast.success("Successfully deleted teacher!");
        navigate(TEACHERS);
      } catch (error: any) {
        const apiMessage = error?.response?.data?.error?.message;
        toast.error(
          apiMessage ?? "Something went wrong while deleting teacher data!"
        );
      }
    }
  };

  const validateTeacherForm = (teacherData: CreateOrUpdateTeacherDto) => {
    let isValid = true;
    setErrors([]);
    const { errors, valid } = validateObject(teacherData);
    isValid = valid;
    setErrors((prevState) => [...prevState, ...errors]);

    return isValid;
  };

  return (
    <>
      {showDeletionModal && (
        <ConfirmActionModal
          closeModal={() => toggleShowDeletionModal(false)}
          confirmedAction={handleTeacherDelete}
        />
      )}
      <div className="section-wrapper">
        <TeacherBasicInfoSection
          addressRef={addressRef}
          emailRef={emailRef}
          firstNameRef={firstNameRef}
          gender={chosenGender}
          lastNameRef={lastNameRef}
          phoneNumber={phoneNumber}
          setGender={setChosenGender}
          setPhoneNumber={setPhoneNumber}
          changeRefValue={changeRefValue}
          errors={errors}
        />
        <div className="section-container">
          <h3>Groups</h3>
          <div className="input-section">
            <CustomSelectDropdown
              chosenOptions={chosenGroups}
              availableOptions={availableGroupOptions}
              handleGroupsChange={handleGroupsChange}
            />
          </div>
        </div>
        {edit && (
          <DataManagement openModal={() => toggleShowDeletionModal(true)} />
        )}
        <FormActions
          edit={edit}
          backAction={handleFormCancel}
          forwardAction={handleSubmit}
        />
      </div>
    </>
  );
};

export default TeacherForm;
