import SettingsMenu from "../../components/SettingsMenu/SettingsMenu";
import { FC, useEffect, useState } from "react";
import { GROUPS } from "../../common/constants/routes.constants";
import {
  DetailsTableHeader,
  MultiUseTableRow,
  OverviewRow,
  PersonWithArrayTableRow,
} from "../../components/DetailsTableComponents/DetailsTableComponents";
import DetailsPageHeader from "../../components/DetailsPageHeader/DetailsPageHeader";
import {
  IApiActivitySubtypes,
  IGroupDetails,
} from "../../common/interfaces/group.interfaces";
import { getGroupDetails } from "../../api/groups.api";
import backArrow from "../../assets/arrow_back.svg";
import GroupForm from "../../components/GroupForm/GroupForm";
import { useParams } from "react-router";
import Loader from "../../components/Loader/Loader";
import {
  capitalizeFirstLetter,
  getStringFromArray,
  mapGroupActivities,
  mapHexToColor,
} from "../../common/utils/helpers";

const GroupDetailsPage: FC<{}> = () => {
  const [group, setGroup] = useState<IGroupDetails>();
  const [editing, toggleEditing] = useState<boolean>(false);
  const [mappedGroupActivities, setMappedGroupActivities] = useState<
    IApiActivitySubtypes[]
  >([]);

  const { groupId } = useParams();

  useEffect(() => {
    if (!editing) fetchData();
  }, [editing]);

  useEffect(() => {
    if (group) {
      const groupActivities = mapGroupActivities(group.activitySubtypes);
      const sleepingItems = group.sleepingSchedules
        .filter(
          (item) =>
            !(item.maxAmountInMinutes === 0 && item.minAmountInMinutes === 0)
        )
        .map((item) => ({
          activityTypeId: item.sleepingScheduleId,
          alphaNumericCode: "",
          meaning: `${item.minAmountInMinutes} - ${item.maxAmountInMinutes} min`,
          description: "",
          entityClass: "Sleep",
          typeIcon: "",
        }));
      const sleepActivities = {
        entityClass: "Sleep",
        items: sleepingItems,
      };
      const activityArray = [...groupActivities, sleepActivities];
      setMappedGroupActivities(activityArray);
    }
  }, [group]);

  const handleToggleEdit = () => {
    toggleEditing(!editing);
  };

  const fetchData = async () => {
    try {
      const groupIdNumber = Number(groupId);
      const response = await getGroupDetails(groupIdNumber);
      setGroup(response);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  return (
    <div className="padding-wrapper">
      <SettingsMenu />
      {group ? (
        <>
          {!editing ? (
            <>
              <DetailsPageHeader
                title={group.adminGroupName}
                initials
                backRoute={GROUPS}
                backTitle="Groups"
                handleState={handleToggleEdit}
              />
              <div className="details-page-layout">
                <div className="details-page-section" style={{ width: "30%" }}>
                  <div className="details-page-section-header">
                    Basic Details
                  </div>
                  <OverviewRow
                    title="Group Name"
                    value={group.adminGroupName}
                  />
                  <OverviewRow
                    title="Color"
                    value={capitalizeFirstLetter(
                      mapHexToColor(group.adminGroupHexColor)
                    )}
                  />
                </div>
                <div className="details-page-section group-details">
                  <div className="multi-section">
                    <div className="vertical-section">
                      <div className="details-page-section-header">
                        Teachers
                      </div>
                      {group.teachers.map((item, index) => (
                        <MultiUseTableRow
                          firstCol={
                            item.teacherFullName +
                            `${item.isPrimary ? " (Head Teacher)" : ""}`
                          }
                          key={index}
                          includeInitials
                        />
                      ))}
                    </div>
                    <div className="vertical-section">
                      <div className="details-page-section-header">
                        Children ({group.children.length})
                      </div>
                      <div className="details-scroll-wrapper">
                        {group.children.map((item, index) => (
                          <MultiUseTableRow
                            firstCol={item.firstName + " " + item.lastName}
                            key={index}
                            includeImg
                            imageUrl={item.avatarURL}
                          />
                        ))}
                      </div>
                    </div>
                    <div className="vertical-section">
                      <div className="details-page-section-header">
                        Activities
                      </div>
                      <DetailsTableHeader titles={["NAME", "VALUES"]} />
                      {mappedGroupActivities.map((item, index) => (
                        <MultiUseTableRow
                          key={index}
                          firstCol={item.entityClass}
                          secondCol={getStringFromArray(
                            item.items.map((obj) => obj.meaning)
                          )}
                        />
                      ))}
                    </div>
                    <div className="vertical-section">
                      <div className="details-page-section-header">
                        Other activities
                      </div>
                      <DetailsTableHeader titles={["NAME", "CHILDREN"]} />
                      {group.additionalActivities.map((item, index) => (
                        <PersonWithArrayTableRow
                          key={index}
                          firstCol={item.name}
                          images={item.children?.map(
                            (item) => item.avatarURL ?? ""
                          )}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                className="back-container"
                onClick={() => toggleEditing(false)}
              >
                <img src={backArrow} alt="Back arrow" />
                Return
              </div>
              <h1>Edit</h1>
              <GroupForm
                handleToggleEdit={handleToggleEdit}
                edit={true}
                groupId={Number(groupId)}
                groupColor={group.adminGroupHexColor}
                groupName={group.adminGroupName}
                groupChildren={group.children}
                groupTeachers={group.teachers}
                additionalActivities={group.additionalActivities}
                groupActivities={mappedGroupActivities}
              />
            </>
          )}
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default GroupDetailsPage;
