import { TextField } from "@mui/material";
import { ChangeEvent, FC, MutableRefObject } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { PlusCircle } from "../../svgs/constants";
import closeIcon from "../../assets/close.png";

const OverviewContactInformation: FC<{
  contactPersonRef: MutableRefObject<HTMLInputElement | undefined>;
  contactEmailRef: MutableRefObject<HTMLInputElement | undefined>;
  phoneNumberArray: string[];
  errors: string[];
  changeRefValue: (
    event: ChangeEvent<HTMLInputElement>,
    customRef: any,
    fieldName?: string
  ) => void;
  handlePhoneChange: (value: string, index: number) => void;
  addPhoneNumber: () => void;
  handleRemovePhone: (index: number) => void;
}> = ({
  contactPersonRef,
  contactEmailRef,
  phoneNumberArray,
  errors,
  changeRefValue,
  handlePhoneChange,
  addPhoneNumber,
  handleRemovePhone,
}) => {
  return (
    <div className="section-container">
      <h3>Contact information</h3>
      <div className="input-section">
        <div>
          <TextField
            inputRef={contactPersonRef}
            label="Contact Person *"
            size="medium"
            onInput={(e: ChangeEvent<HTMLInputElement>) =>
              changeRefValue(e, contactPersonRef, "contactPersonFullName")
            }
            sx={{ width: "100%" }}
          />
          {errors.includes("contactPersonFullName") && (
            <p className="error-text">Contact person can not be empty</p>
          )}
        </div>
        <div>
          <TextField
            inputRef={contactEmailRef}
            label="Email *"
            size="medium"
            onInput={(e: ChangeEvent<HTMLInputElement>) =>
              changeRefValue(e, contactEmailRef, "email")
            }
            sx={{ width: "100%" }}
          />
          {errors.includes("email") && (
            <p className="error-text">Email can not be empty</p>
          )}
        </div>
        <div className="phone-section">
          {phoneNumberArray.map((item, index) => (
            <div key={index} className="phone-input">
              <PhoneInput
                country={"rs"}
                preferredCountries={["rs", "pt", "no", "gb"]}
                specialLabel="Phone Number *"
                value={item}
                onChange={(phone) => handlePhoneChange(phone, index)}
              />
              {index !== 0 && (
                <img
                  className="close-icon"
                  src={closeIcon}
                  onClick={() => {
                    handleRemovePhone(index);
                  }}
                  alt="close"
                />
              )}
            </div>
          ))}
          {errors.includes("phoneNumbers") && (
            <p className="error-text">Must have at least one phone number</p>
          )}
        </div>
        <div onClick={addPhoneNumber} className="add-parent">
          <PlusCircle />
          Add phone number
        </div>
      </div>
    </div>
  );
};

export default OverviewContactInformation;
