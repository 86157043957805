export const LOGIN = "/login";
export const SETTINGS = "/settings";
export const OVERVIEW = "/";

export const GROUPS = "/groups";
export const GROUP_FORM = "/group-form";
export const GROUP_DETAILS = "/group-details";

export const TEACHERS = "/teachers";
export const TEACHER_FORM = "/teacher-form";
export const TEACHER_DETAILS = "/teacher-details";

export const CHILDREN = "/children";
export const CHILD_FORM = "/child-form";
export const CHILD_DETAILS = "/child-details";

export const PARENTS = "/parents";
export const PARENT_FORM = "/parent-form";
export const PARENT_DETAILS = "/parent-details";

export const NOT_FOUND = "*";
