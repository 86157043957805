import { Box, Stack, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import "./SearchBar.scss";
import { FC, useEffect, useState } from "react";

const SearchBar: FC<{
  setSearchValue: (value: string) => void;
  searchValue?: string;
}> = ({ setSearchValue, searchValue }) => {
  const [value, setValue] = useState(searchValue ?? "");

  useEffect(() => {
    const timer = setTimeout(() => setSearchValue(value), 500);
    return () => clearTimeout(timer);
  }, [value]);

  return (
    <Stack direction="row" justifyContent="flex-end">
      <Box className="search-box">
        <SearchIcon sx={{ ml: 1 }} />
        <TextField
          placeholder="Search by name"
          value={searchValue}
          onChange={(e: any) => setValue(e.target.value)}
        />
      </Box>
    </Stack>
  );
};

export default SearchBar;
