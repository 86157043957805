import SettingsMenu from "../../components/SettingsMenu/SettingsMenu";
import { GROUPS } from "../../common/constants/routes.constants";
import { useNavigate } from "react-router";
import backArrow from "../../assets/arrow_back.svg";
import { FC } from "react";
import GroupForm from "../../components/GroupForm/GroupForm";

const GroupsFormPage: FC<{}> = ({}) => {
  const navigate = useNavigate();

  return (
    <div className="padding-wrapper">
      <SettingsMenu />
      <div className="back-container" onClick={() => navigate(GROUPS)}>
        <img src={backArrow} alt="Back arrow" />
        Return
      </div>
      <h1>Create a new group</h1>
      <GroupForm />
    </div>
  );
};

export default GroupsFormPage;
