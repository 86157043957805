import SettingsMenu from "../../components/SettingsMenu/SettingsMenu";
import { FC, useEffect, useState } from "react";
import { CHILDREN } from "../../common/constants/routes.constants";
import {
  DetailsTableHeader,
  MultiUseTableRow,
  OverviewRow,
  PersonWithArrayTableRow,
} from "../../components/DetailsTableComponents/DetailsTableComponents";
import DetailsPageHeader from "../../components/DetailsPageHeader/DetailsPageHeader";
import ChildForm from "../../components/ChildForm/ChildForm";
import { ReturnSection } from "../../components/FormActions/FormActions";
import Loader from "../../components/Loader/Loader";
import { IChildrenDetails } from "../../common/interfaces/children.interface";
import { useParams } from "react-router";
import { getChildrenDetails } from "../../api/children.api";
import {
  capitalizeFirstLetter,
  formatDateString,
  mapGenderValue,
} from "../../common/utils/helpers";

const ChildDetailsPage: FC<{}> = () => {
  const [child, setChild] = useState<IChildrenDetails>();
  const [editing, toggleEditing] = useState<boolean>(false);

  const { childId } = useParams();

  useEffect(() => {
    if (!editing) fetchData();
  }, [editing]);

  const handleToggleEdit = () => {
    toggleEditing(!editing);
  };

  const fetchData = async () => {
    try {
      const parentIdNumber = Number(childId);
      const response = await getChildrenDetails(parentIdNumber);
      setChild(response);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  return (
    <div className="padding-wrapper">
      <SettingsMenu />
      {child ? (
        <>
          {!editing ? (
            <>
              <DetailsPageHeader
                title={child.name + " " + child.surname}
                imgUrl={child.childAvatarURL}
                backRoute={CHILDREN}
                backTitle="Children"
                handleState={handleToggleEdit}
              />
              <div className="details-page-layout">
                <div className="details-page-section" style={{ width: "30%" }}>
                  <div className="details-page-section-header">
                    Basic Details
                  </div>
                  <OverviewRow title="First Name" value={child.name} />
                  <OverviewRow title="Last Name" value={child.surname} />
                  <OverviewRow
                    title="Date of Birth"
                    value={formatDateString(child.birthDate)}
                  />
                  <OverviewRow
                    title="Gender"
                    value={mapGenderValue(child.gender)}
                  />
                  <OverviewRow title="Address" value="Milana Jovanovica" />
                </div>
                <div className="details-page-section child-details">
                  <div className="multi-section">
                    <div className="vertical-section">
                      <div className="details-page-section-header">Parents</div>
                      <DetailsTableHeader titles={["NAME", "RELATION"]} />
                      {child.parents.map((parent: any) => (
                        <MultiUseTableRow
                          firstCol={parent.parentFullName}
                          secondCol={
                            (parent.parentRelation
                              ? capitalizeFirstLetter(parent.parentRelation)
                              : "Parent") +
                            (parent.isPrimaryCarer ? " (Primary Carer)" : "")
                          }
                          includeInitials
                        />
                      ))}
                    </div>
                    <div className="vertical-section">
                      <div className="details-page-section-header">Group</div>
                      <DetailsTableHeader titles={["NAME", "TEACHERS"]} />
                      <PersonWithArrayTableRow
                        firstCol={child.group.groupName}
                        initials={child.teachers.map(
                          (item) => item.teacherFullName
                        )}
                      />
                    </div>
                    <div className="vertical-section">
                      <div className="details-page-section-header">
                        Other activities
                      </div>
                      {child.additionalActivities.map((item, index) => (
                        <div key={index} className="details-page-section-row">
                          {item.additionalActivityName}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <ReturnSection handleToggleEdit={handleToggleEdit} />
              <ChildForm
                edit
                childId={Number(childId)}
                firstName={child.name}
                lastName={child.surname}
                address={child.address}
                gender={child.gender}
                group={child.group}
                parents={child.parents}
                handleToggleEdit={handleToggleEdit}
                dateOfBirth={child.birthDate}
              />
            </>
          )}
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default ChildDetailsPage;
