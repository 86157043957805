import { jwtDecode } from "jwt-decode";
import { LANGUAGE_OPTIONS, NUMBER_REGEX } from "../constants/common.constants";
import {
  IApiActivitySubtypes,
  IGroupDetailsActivitySubtype,
  IGroupDetailsAdditionalActivity,
  ISleepingActivitySubtype,
} from "../interfaces/group.interfaces";
import { ILanguage } from "../interfaces/kindergarten.interfaces";

export const mapColorsToHex = (color: string) => {
  const colorMap: Record<string, string> = {
    yellow: "#ffd863",
    pistachio: "#9dc368",
    purple: "#786cb2",
    sky: "#85bce6",
    green: "#3a9e74",
    orange: "#ffaa5b",
    lavander: "#d2aee6",
    watermelon: "#f6a094",
    red: "#dc554e",
    sea: "#4787b8",
  };

  return colorMap[color.toLowerCase()] || "grey";
};

export const mapHexToColor = (hex: string) => {
  const hexMap: Record<string, string> = {
    "#ffd863": "yellow",
    "#9dc368": "pistachio",
    "#786cb2": "purple",
    "#85bce6": "sky",
    "#3a9e74": "green",
    "#ffaa5b": "orange",
    "#d2aee6": "lavander",
    "#f6a094": "watermelon",
    "#dc554e": "red",
    "#4787b8": "sea",
  };

  return hexMap[hex.toLowerCase()] || "unknown";
};

export const mapGenderValue = (gender: string) => {
  const genderMap: Record<string, string> = {
    f: "Female",
    m: "Male",
  };

  return genderMap[gender.toLowerCase()] || "Other";
};

export const getInitials = (name: string) => {
  const nameParts = name.split(" ");

  const firstInitial = nameParts[0] ? nameParts[0][0] : "";

  const lastInitial = nameParts[nameParts.length - 1]
    ? nameParts[nameParts.length - 1][0]
    : "";

  const initials = firstInitial + lastInitial;

  return initials;
};

export const checkIfCharacterIsNumber = (value: string) => {
  if (value === "") return true;
  return NUMBER_REGEX.test(value);
};

export const decodeUserFromToken = (token: string): any => {
  try {
    const decodedToken: any = jwtDecode(token);
    return decodedToken || null;
  } catch (error) {
    console.error("Error decoding user from token", error);
    return undefined;
  }
};

export const validateArray = (arr: string[]): boolean => {
  if (!arr || arr.length === 0) {
    return false;
  }

  for (const str of arr) {
    const trimmedStr = str.trim();

    if (!trimmedStr) {
      return false;
    }
  }

  return true;
};

export const validateObject = (data: any) => {
  let valid = true;
  let errors: string[] = [];

  Object.keys(data).forEach((field) => {
    const value = data[field];

    if (typeof value === "string") {
      if (value === "") {
        errors = [...errors, field];
        valid = false;
      }
    }

    if (typeof value === "number") {
      if (isNaN(value) || value === 0) {
        errors = [...errors, field];
        valid = false;
      }
    }
  });

  return { valid, errors };
};

export const removeWordFromArray = (wordToRemove: string, arr: string[]) => {
  const index = arr.indexOf(wordToRemove);
  if (index !== -1) {
    arr.splice(index, 1);
  }
  return arr;
};

export const formatDateString = (dateString: string) => {
  const date = new Date(dateString);

  const formattedDate = date.toLocaleDateString();

  return formattedDate;
};

export const mapGroupActivities = (
  originalArray: IGroupDetailsActivitySubtype[]
) => {
  const groupActivities = originalArray.reduce(
    (result, currentObj) => {
      const existingEntry = result.find(
        (entry) => entry.entityClass === currentObj.entityClass
      );

      if (existingEntry) {
        existingEntry.items.push(currentObj);
      } else {
        result.push({
          entityClass: currentObj.entityClass,
          items: [currentObj],
        });
      }

      return result;
    },
    [] as { entityClass: string; items: IGroupDetailsActivitySubtype[] }[]
  );

  return groupActivities;
};

export const getStringFromArray = (array: string[]) => {
  return array.join(", ");
};

export const getLanguageDetailsFromCode = (languageCode: string) => {
  switch (languageCode) {
    case "en":
      return {
        label: LANGUAGE_OPTIONS[0].name,
        imageUrl: LANGUAGE_OPTIONS[0].icon,
      };
    case "nr":
      return {
        label: LANGUAGE_OPTIONS[1].name,
        imageUrl: LANGUAGE_OPTIONS[1].icon,
      };
    case "sr":
      return {
        label: LANGUAGE_OPTIONS[2].name,
        imageUrl: LANGUAGE_OPTIONS[2].icon,
      };
    case "pr":
      return {
        label: LANGUAGE_OPTIONS[3].name,
        imageUrl: LANGUAGE_OPTIONS[3].icon,
      };
    default:
      return {
        label: "Unsupported language",
        imageUrl: "",
      };
  }
};

export const getLanguageCodeById = (languageId: number) => {
  switch (languageId) {
    case 1:
      return "en";
    case 2:
      return "nr";
    case 3:
      return "sr";
    case 4:
      return "pr";
    default:
      return "";
  }
};

export const sortAndFilterLanguages = (
  languageArray: ILanguage[]
): ILanguage[] => {
  const filteredLanguages = languageArray.filter(
    (language) => language.isDefaultLanguage || language.isCurrentLanguage
  );
  return filteredLanguages.sort((a, b) => {
    if (a.isDefaultLanguage && !a.isCurrentLanguage) {
      return -1;
    }
    if (b.isDefaultLanguage && !b.isCurrentLanguage) {
      return 1;
    }
    if (a.isCurrentLanguage && !b.isCurrentLanguage) {
      return -1;
    }
    if (!a.isCurrentLanguage && b.isCurrentLanguage) {
      return 1;
    }
    return 0;
  });
};

export const transformActivitySubtypes = (data: any) => {
  const transformedArray: IApiActivitySubtypes[] = [];

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const entityClass = key;
      let items = data[key];

      if (key === "sleep") {
        items = items
          .map((sleepItem: ISleepingActivitySubtype) => ({
            activityTypeId: sleepItem.sleepingScheduleId,
            alphaNumericCode: "0",
            meaning: `${sleepItem.minAmountInMinutes} - ${sleepItem.maxAmountInMinutes} min`,
            description: "",
            entityClass: "sleep",
            typeIcon: "",
          }))
          .filter(
            (transformedItem: IGroupDetailsActivitySubtype) =>
              transformedItem.meaning !== "0 - 0 min" &&
              transformedItem.meaning !== "-1 - -1 min"
          );
      }

      const transformedObject: IApiActivitySubtypes = {
        entityClass,
        items,
      };

      transformedArray.push(transformedObject);
    }
  }

  return transformedArray;
};

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const deepCopyAdditionalActivities = (
  initialActivities: IGroupDetailsAdditionalActivity[]
) => {
  const initialActivityArrayCopy: IGroupDetailsAdditionalActivity[] =
    initialActivities.map(deepCopyObject);

  return initialActivityArrayCopy;
};

export const deepCopyObject = (original: IGroupDetailsAdditionalActivity) => {
  const copiedObject: IGroupDetailsAdditionalActivity = {
    ...original,
    children: [],
  };

  copiedObject.children = original.children?.map((child) => ({ ...child }));

  return copiedObject;
};

export const findParentsRelationship = (children: any) => {
  let relation = "Parent";
  for (const child of children) {
    if (child.childRelation !== null)
      relation = capitalizeFirstLetter(child.childRelation);
  }

  return relation;
};

export const toggleSort = (sort: string) => {
  if (sort === "asc") return "desc";
  return "asc";
};

export const grabActivityIdsForValidation = (
  mappedActivityOptions: IApiActivitySubtypes[]
) => {
  const foodIds = [];
  const toiledIds = [];

  for (const activity of mappedActivityOptions) {
    if (activity.entityClass === "food") {
      for (const item of activity.items) {
        foodIds.push(item.activityTypeId);
      }
    }

    if (activity.entityClass === "toilet") {
      for (const item of activity.items) {
        toiledIds.push(item.activityTypeId);
      }
    }
  }

  return { foodIds, toiledIds };
};
