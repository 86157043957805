import React, { useContext, useEffect, useState } from "react";
import { Box, outlinedInputClasses, Stack } from "@mui/material";
import Sidebar from "../Sidebar/Sidebar";
import {
  createTheme,
  ThemeProvider,
  Theme,
  useTheme,
} from "@mui/material/styles";
import { AuthContext } from "../../../store/AuthContext";
import { useNavigate } from "react-router";
import { LOGIN } from "../../../common/constants/routes.constants";
import Loader from "../../Loader/Loader";

type BasicLayoutProps = {
  content: JSX.Element;
};

const customTheme = (outerTheme: Theme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "--TextField-brandBorderColor": "#D9D9D9",
            "--TextField-brandBorderHoverColor": "#3F3273",
            "--TextField-brandBorderFocusedColor": "#3F3273",
            "& label.Mui-focused": {
              color: "var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: "var(--TextField-brandBorderColor)",
          },
          root: {
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: "var(--TextField-brandBorderHoverColor)",
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: "var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            "&:before, &:after": {
              borderBottom: "2px solid var(--TextField-brandBorderColor)",
            },
            "&:hover:not(.Mui-disabled, .Mui-error):before": {
              borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
            },
            "&.Mui-focused:after": {
              borderBottom:
                "2px solid var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            "&:before": {
              borderBottom: "2px solid var(--TextField-brandBorderColor)",
            },
            "&:hover:not(.Mui-disabled, .Mui-error):before": {
              borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
            },
            "&.Mui-focused:after": {
              borderBottom:
                "2px solid var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
    },
  });

const BasicLayout: React.FC<BasicLayoutProps> = (props: BasicLayoutProps) => {
  const outerTheme = useTheme();

  const { authState } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setIsLoading] = useState(true);

  useEffect(() => {
    void checkForToken();
  }, []);

  const checkForToken = () => {
    if (!authState.token || !authState.refresh_token) {
      navigate(LOGIN);
    } else {
      setIsLoading(false);
    }
  };

  return (
    <ThemeProvider theme={customTheme(outerTheme)}>
      {loading ? (
        <Loader />
      ) : (
        <Box>
          <Stack direction="row">
            <Sidebar />
            <Box className="content">{props.content}</Box>
          </Stack>
        </Box>
      )}
    </ThemeProvider>
  );
};

export default BasicLayout;
