import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { RestCodes } from "../common/enums/common.enums";

type HttpMethod = "POST" | "GET" | "PUT" | "PATCH" | "DELETE";

export const baseURL = process.env.REACT_APP_BASE_URL;

export const axiosInstance = axios.create({
  baseURL,
});

export function postRequest<T = any>(
  path: string,
  data: Record<string, any>
): Promise<T> {
  return makeRequest("POST", path, data);
}

export function putRequest<T = any>(
  path: string,
  data: Record<string, any>
): Promise<T> {
  return makeRequest("PUT", path, data);
}

export function patchRequest<T = any>(
  path: string,
  data: Record<string, any>
): Promise<T> {
  return makeRequest("PATCH", path, data);
}

export function deleteRequest<T = any>(
  path: string,
  // Check if data is ever needed here? Should not be
  data?: Record<string, any>
): Promise<T> {
  return makeRequest("DELETE", path, data);
}

export function getRequest<T = any>(path: string): Promise<T> {
  return makeRequest("GET", path);
}

export function postMultiPart<T = any>(
  path: string,
  data: Record<string, any>
): Promise<T> {
  return makeRequest("POST", path, data, {
    "Content-Type": "multipart/form-data",
  });
}

export async function makeRequest(
  method: HttpMethod,
  path: string,
  data?: Record<string, any>,
  headers?: any
) {
  const requestConfig: AxiosRequestConfig = {
    method,
    url: path,
    data,
    headers,
  };

  const response = await axiosInstance.request<any>(requestConfig);

  if (response.status >= RestCodes.BadRequest) {
    throw AxiosError;
  }

  if (method === "DELETE") {
    return response;
  }

  return response.data;
}
