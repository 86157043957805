import React from "react";
import childAvatar from "../../assets/childAvatar.png";
import "../Tables/Tables.scss";
import { getInitials } from "../../common/utils/helpers";

interface NameAndImageCellProps {
  personId: number;
  fullName: string;
  imgUrl?: string;
}

const NameAndImageCell: React.FC<NameAndImageCellProps> = ({
  fullName,
  imgUrl,
}) => {
  return (
    <>
      <div className="tablerow-avatar">
        <img src={imgUrl ? imgUrl : childAvatar} alt="person" />
      </div>
      <span>{fullName}</span>
    </>
  );
};

export const NameAndInitialsCell: React.FC<NameAndImageCellProps> = ({
  fullName,
}) => {
  return (
    <>
      <div className="tablerow-avatar">
        <div
          className="additional-number-container"
          style={{ marginRight: "8px" }}
        >
          {getInitials(fullName)}
        </div>
      </div>
      <span>{fullName}</span>
    </>
  );
};

export default NameAndImageCell;
