import React from "react";
import childAvatar from "../../assets/childAvatar.png";
import "../Tables/Tables.scss";

const ImageArray: React.FC<{ images: string[] }> = ({ images }) => {
  return (
    <div className="circle-array-wrapper">
      {images.slice(0, 4).map((item, index) => (
        <img
          src={item ? item : childAvatar}
          alt="Avatar"
          key={index}
          style={{ zIndex: 4 - index }}
        />
      ))}
      {images.length > 4 && (
        <div className="additional-number-container">{`+${
          images.length - 4
        }`}</div>
      )}
    </div>
  );
};

export default ImageArray;
