import SettingsMenu from "../../components/SettingsMenu/SettingsMenu";
import backArrow from "../../assets/arrow_back.svg";
import { FC } from "react";
import { useNavigate } from "react-router";
import { CHILDREN } from "../../common/constants/routes.constants";
import ChildForm from "../../components/ChildForm/ChildForm";

const ChildFormPage: FC<{ edit?: boolean }> = ({ edit }) => {
  const navigate = useNavigate();

  return (
    <div className="padding-wrapper">
      <SettingsMenu />
      <div className="back-container" onClick={() => navigate(CHILDREN)}>
        <img src={backArrow} alt="Back arrow" />
        Children
      </div>
      <h1>{edit ? "Edit child" : "Create a new child"}</h1>
      <ChildForm />
    </div>
  );
};

export default ChildFormPage;
