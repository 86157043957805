import SettingsMenu from "../../components/SettingsMenu/SettingsMenu";
import backArrow from "../../assets/arrow_back.svg";
import { FC } from "react";
import { useNavigate } from "react-router";
import { TEACHERS } from "../../common/constants/routes.constants";
import TeacherForm from "../../components/TeacherForm/TeacherForm";

const TeacherFormPage: FC<{ edit?: boolean }> = ({ edit }) => {
  const navigate = useNavigate();

  return (
    <div className="padding-wrapper">
      <SettingsMenu />
      <div className="back-container" onClick={() => navigate(TEACHERS)}>
        <img src={backArrow} alt="Back arrow" />
        Teachers
      </div>
      <h1>{edit ? "Edit teacher" : "Create a new teacher"}</h1>
      <TeacherForm />
    </div>
  );
};

export default TeacherFormPage;
