import { createContext, useCallback, useState } from "react";
import { baseURL, postRequest } from "../api/http";
import { decodeUserFromToken } from "../common/utils/helpers";
import Cookies from "js-cookie";
import { LOGIN } from "../common/constants/routes.constants";
import axios from "axios";
import { LOGIN_API } from "../common/constants/api.constants";

interface IAuthContext {
  authState: AuthState;
  login: (token: string, refresh_token: string) => void;
  refreshToken: (failedReq?: any) => Promise<any>;
  logout: () => Promise<void>;
}

interface AuthState {
  token?: string;
  refresh_token?: string;
  username?: string;
}

const AuthContext = createContext({} as IAuthContext);
const { Provider } = AuthContext;

const AuthProvider = ({ children }: any) => {
  const [authState, setAuthState] = useState<AuthState>({
    refresh_token: Cookies.get("refresh_token"),
    token: Cookies.get("token"),
  });

  const login = useCallback((token: string, refresh_token: string) => {
    const user = decodeUserFromToken(token);
    const username = user.username;
    setAuthState({ token, refresh_token, username });
    Cookies.set("token", token);
    Cookies.set("refresh_token", refresh_token);
  }, []);

  const refreshToken = useCallback(
    async (failedReq?: any) => {
      try {
        if (!authState) {
          return;
        }

        if (failedReq.config.url === LOGIN_API) return;

        const response = await axios.post(`${baseURL}/token/refresh`, {
          refresh_token: Cookies.get("refresh_token"),
        });

        const { token, refresh_token } = response.data;

        const newCredentials: AuthState = {
          ...authState,
          token: token,
          refresh_token: refresh_token,
        };

        Cookies.set("token", token);
        Cookies.set("refresh_token", refresh_token);

        setAuthState(newCredentials);

        if (failedReq) {
          failedReq.response.config.headers["Authorization"] =
            "Bearer " + token;
        }
        Promise.resolve();
      } catch (error) {
        console.error("Token refresh failed", error);
        window.location.href = LOGIN;
      }
    },
    [authState]
  );

  const logout = useCallback(async () => {
    if (authState.refresh_token) {
      await postRequest("/logout", {
        refresh_token: Cookies.get("refresh_token"),
      });
    }
    Cookies.remove("token");
    Cookies.remove("refresh_token");
    setAuthState({
      token: undefined,
      refresh_token: undefined,
      username: "",
    });
  }, [authState]);

  return (
    <Provider value={{ authState, login, logout, refreshToken }}>
      {children}
    </Provider>
  );
};

export { AuthContext, AuthProvider };
