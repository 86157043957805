import { CreateOrUpdateTeacherDto } from "../common/dtos/teacher.dto";
import { IPaginatedTeacherTable } from "../common/interfaces/teacher.interface";
import { ADMIN, TEACHERS } from "./api.routes";
import { deleteRequest, getRequest, postRequest } from "./http";

export function getAllTeachers(
  queryParams?: string
): Promise<IPaginatedTeacherTable> {
  return getRequest(`${ADMIN}/${TEACHERS}${queryParams ? queryParams : ""}`);
}

export function getTeacherDetails(teacherId: number): Promise<any> {
  return getRequest(`${ADMIN}/${TEACHERS}/${teacherId}`);
}

export function createOrUpdateTeacher(data: CreateOrUpdateTeacherDto) {
  return postRequest(`${ADMIN}/${TEACHERS}`, data);
}

export function deleteTeacher(groupId: number): Promise<void> {
  return deleteRequest(`${ADMIN}/${TEACHERS}/${groupId}`);
}
