import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { NameAndInitialsCell } from "../../components/Tables/NameAndImageCell";
import React, { useEffect, useState } from "react";
import SettingsMenu from "../../components/SettingsMenu/SettingsMenu";
import TitleArea from "../../components/Layout/TitleArea/TitleArea";
import SearchBar from "../../components/SearchBar/SearchBar";
import { useNavigate } from "react-router";
import { TEACHER_DETAILS } from "../../common/constants/routes.constants";
import { getAllTeachers } from "../../api/teachers.api";
import { ITableTeacher } from "../../common/interfaces/teacher.interface";
import Loader from "../../components/Loader/Loader";
import { ITEMS_PER_PAGE } from "../../common/constants/common.constants";
import { toggleSort } from "../../common/utils/helpers";

export const columns: GridColDef[] = [
  {
    field: "name",
    headerName: "NAME",
    headerClassName: "table-header",
    flex: 15,
    renderCell: (params) => {
      return (
        <NameAndInitialsCell
          personId={params.row.id}
          fullName={params.row.name + " " + params.row.surname}
        />
      );
    },
  },
  {
    // TODO: CSS headerAlign, groups take too much space
    field: "groups",
    headerName: "GROUPS",
    headerClassName: "table-header",
    sortable: false,
    width: 125,
    flex: 10,
    renderCell: (params) => (
      <>
        {params.row.groups.length !== 0 ? (
          <>
            {params.row.groups.map((item: any, index: number) => (
              <React.Fragment key={index}>
                {item.name}
                {index < params.value.length - 1 && <span>,&nbsp;</span>}
              </React.Fragment>
            ))}
          </>
        ) : (
          <>No groups assigned</>
        )}
      </>
    ),
  },
  {
    field: "email",
    headerName: "EMAIL",
    headerClassName: "table-header",
    sortable: false,
    flex: 10,
  },
  {
    field: "phone",
    headerName: "PHONE NUMBER",
    headerClassName: "table-header",
    sortable: false,
    flex: 10,
  },
  {
    field: "address",
    headerName: "ADDRESS",
    headerClassName: "table-header",
    sortable: false,
    flex: 10,
  },
];

const TeachersPage = () => {
  const [teachers, setTeachers] = useState<ITableTeacher[]>();
  const [page, setPage] = useState(1);
  const [sortOrder, setSortOrder] = useState("");
  const [filter, setFilter] = useState("");
  const [queryParam, setQueryParam] = useState("");
  const [totalCount, setTotalCount] = useState<number>();

  const navigate = useNavigate();

  useEffect(() => {
    if (queryParam) fetchData();
  }, [queryParam]);

  useEffect(() => {
    setQueryParam(
      `?page=${page}&paginationItemsPerPage=${ITEMS_PER_PAGE}&filter=${filter}&sortBy=name&sortOrder=${sortOrder}`
    );
  }, [page, sortOrder, filter]);

  const fetchData = async () => {
    try {
      const response = await getAllTeachers(queryParam);
      setTeachers(response.data);
      setTotalCount(response.totalCount);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const handleRowClick = (params: any) => {
    navigate(`${TEACHER_DETAILS}/${params.id}`);
  };

  const handlePageChange = (e: any) => {
    setPage(e.page + 1);
  };

  const handleSortChange = () => {
    setSortOrder(toggleSort(sortOrder));
  };

  return (
    <div style={{ padding: "30px 40px" }}>
      <SettingsMenu />
      <TitleArea />
      {teachers ? (
        <>
          <SearchBar setSearchValue={(value) => setFilter(value)} />
          <DataGrid
            onRowClick={(params) => handleRowClick(params)}
            getRowId={(row) => row.teacherID}
            rows={teachers}
            disableColumnMenu
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            rowCount={totalCount}
            sx={{ border: 0 }}
            onPaginationModelChange={(e: any) => handlePageChange(e)}
            onSortModelChange={handleSortChange}
            sortingMode="server"
            paginationMode="server"
            sortingOrder={["asc", "desc"]}
            disableColumnSelector={true}
            localeText={{ noRowsLabel: "No data available" }}
          />
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default TeachersPage;
