export const ADMIN = "/admin";
export const KINDERGARTEN = "/admin/kindergarten";

export const DETAILS = "details";
export const LANGUAGES = "languages";
export const KINDERGARTEN_ADD_ACTIVITIES = "additional_activities";

export const PARENTS = "parents";

export const CHILDREN = "children";

export const GROUP = "group";
export const GROUPS = "groups";

export const TEACHER = "teacher";
export const TEACHERS = "teachers";
