export enum RestCodes {
  Ok = 200,
  Success = 201,
  BadRequest = 400,
  Unathorized = 401,
  Forbidden = 403,
  NotFound = 404,
}

export enum Relationship {
  Father = "Father",
  Mother = "Mother",
  Guardian = "Guardian",
}

export enum SelectedOptions {
  All = "All",
  None = "None",
  Custom = "Custom",
}

export enum DtoFieldStatus {
  New = "new",
  Deleted = "deleted",
  Unaffected = "unaffected",
  Updated = "updated",
}

export enum Gender {
  Female = "f",
  Male = "m",
}
