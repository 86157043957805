import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import "react-phone-input-2/lib/material.css";
import { UpdateKindergartenInfoDto } from "../../common/dtos/kindergarten.dto";
import { updateKindergartenInfo } from "../../api/kindergarten.api";
import { IKindergartenInfo } from "../../common/interfaces/kindergarten.interfaces";
import {
  removeWordFromArray,
  validateArray,
  validateObject,
} from "../../common/utils/helpers";
import OverviewBasicDetails from "./OverviewBasicDetails";
import OverviewContactInformation from "./OverviewContactInformation";
import FormActions from "../FormActions/FormActions";
import { toast } from "react-toastify";

const OverviewForm: FC<{
  kindergartenId: number;
  name: string;
  address: string;
  PIB: string;
  companyRegistrationNumber: string;
  representativeFullName: string;
  contactPersonFullName: string;
  phoneNumbers: string[];
  email: string;
  kLogo: any;
  handleToggleEdit: () => void;
}> = ({
  kindergartenId,
  name,
  address,
  PIB,
  companyRegistrationNumber,
  representativeFullName,
  contactPersonFullName,
  phoneNumbers,
  email,
  kLogo,
  handleToggleEdit,
}) => {
  const [phoneNumberArray, setPhoneNumberArray] = useState<string[]>([]);
  const [file, setFile] = useState<File | undefined>();
  const [errors, setErrors] = useState<string[]>([]);

  const nameRef = useRef<HTMLInputElement>();
  const addressRef = useRef<HTMLInputElement>();
  const pibRef = useRef<HTMLInputElement>();
  const regNumberRef = useRef<HTMLInputElement>();
  const representativeRef = useRef<HTMLInputElement>();
  const contactPersonRef = useRef<HTMLInputElement>();
  const contactEmailRef = useRef<HTMLInputElement>();

  useEffect(() => {
    populateFields();
  }, []);

  const handleSubmit = async () => {
    const data: UpdateKindergartenInfoDto = {
      kindergartenInfo: {
        kindergartenId,
        name: nameRef.current!.value,
        address: addressRef.current!.value,
        PIB: pibRef.current!.value,
        companyRegistrationNumber: Number(regNumberRef.current!.value),
        representativeFullName: representativeRef.current!.value,
        contactPersonFullName: contactPersonRef.current!.value,
        phoneNumbers: phoneNumberArray,
        email: contactEmailRef.current!.value,
      },
      logo: file ?? null,
    };

    if (!validateFields(data.kindergartenInfo)) return;

    try {
      await updateKindergartenInfo(data);
      toast.success("Successfully updated kindergarten info!");
      handleToggleEdit();
    } catch (error: any) {
      console.log(error.message);
      toast.error("Something went wrong while saving kindergarten data");
    }
  };

  const changeRefValue = (
    event: ChangeEvent<HTMLInputElement>,
    customRef: any,
    fieldName?: string
  ) => {
    setErrors((errors) => [...removeWordFromArray(fieldName!, errors)]);
    if (customRef.current) customRef.current.value = event.target.value;
  };

  const handlePhoneChange = (value: string, index: number) => {
    setErrors((errors) => [...removeWordFromArray("phoneNumbers", errors)]);
    const updatedPhoneNumbers = [...phoneNumberArray];
    updatedPhoneNumbers[index] = value;
    setPhoneNumberArray(updatedPhoneNumbers);
  };

  const addPhoneNumber = () => {
    setPhoneNumberArray((numbers) => [...numbers, ""]);
  };

  const handleRemovePhone = (index: number) => {
    phoneNumberArray.splice(index, 1);
    setPhoneNumberArray([...phoneNumberArray]);
  };

  const populateFields = () => {
    if (nameRef.current) nameRef.current.value = name;
    if (addressRef.current) addressRef.current.value = address;
    if (pibRef.current) pibRef.current.value = PIB;
    if (regNumberRef.current)
      regNumberRef.current.value = companyRegistrationNumber;
    if (representativeRef.current)
      representativeRef.current.value = representativeFullName;
    if (contactPersonRef.current)
      contactPersonRef.current.value = contactPersonFullName;
    if (contactEmailRef.current) contactEmailRef.current.value = email;
    if (kLogo) setFile(kLogo);
    setPhoneNumberArray([...phoneNumbers]);
  };

  const validateFields = (data: IKindergartenInfo) => {
    let isValid = true;
    setErrors([]);
    const { errors, valid } = validateObject(data);

    isValid = valid;
    setErrors((current) => [...current, ...errors]);

    if (!validateArray(phoneNumberArray)) {
      setErrors((errors) => [...errors, "phoneNumbers"]);
      isValid = false;
    }

    return isValid;
  };

  return (
    <div className="section-wrapper">
      <OverviewBasicDetails
        nameRef={nameRef}
        addressRef={addressRef}
        pibRef={pibRef}
        regNumberRef={regNumberRef}
        representativeRef={representativeRef}
        file={file}
        errors={errors}
        changeRefValue={changeRefValue}
        setFile={setFile}
      />
      <OverviewContactInformation
        contactPersonRef={contactPersonRef}
        contactEmailRef={contactEmailRef}
        phoneNumberArray={phoneNumberArray}
        errors={errors}
        changeRefValue={changeRefValue}
        handlePhoneChange={handlePhoneChange}
        addPhoneNumber={addPhoneNumber}
        handleRemovePhone={handleRemovePhone}
      />

      <FormActions
        backAction={handleToggleEdit}
        forwardAction={handleSubmit}
        edit
      />
    </div>
  );
};

export default OverviewForm;
