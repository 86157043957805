import { FC } from "react";
import childAvatar from "../../assets/childAvatar.png";
import InitialsArray from "../Tables/InitialsArray";
import ImageArray from "../Tables/ImageArray";
import { getInitials } from "../../common/utils/helpers";

export const DetailsTableHeader: FC<{ titles: string[] }> = ({ titles }) => {
  const width = 100 / titles.length;

  return (
    <div className="details-table-header">
      {titles.map((item, index) => (
        <div
          className="details-table-header-section"
          style={{ width: `${width}%` }}
          key={index}
        >
          {item}
        </div>
      ))}
    </div>
  );
};

export const MultiUseTableRow: FC<{
  firstCol: string;
  includeImg?: boolean;
  imageUrl?: string;
  includeInitials?: boolean;
  secondCol?: string;
  thirdCol?: string;
}> = ({
  firstCol,
  secondCol,
  thirdCol,
  includeImg,
  includeInitials,
  imageUrl,
}) => {
  const calculateWidth = () => {
    if (secondCol && thirdCol) {
      return "33.33%";
    } else {
      return "50%";
    }
  };

  return (
    <div className="details-table-row">
      <div
        className="details-table-row-section"
        style={{ width: `${calculateWidth}` }}
      >
        {includeImg && <img src={imageUrl ?? childAvatar} alt="Person" />}
        {includeInitials && (
          <div className="additional-number-container">
            {getInitials(firstCol.split(" (")[0])}
          </div>
        )}
        {firstCol}
      </div>
      {secondCol && (
        <div
          className="details-table-row-section"
          style={{ width: `${calculateWidth}` }}
        >
          {secondCol}
        </div>
      )}
      {thirdCol && (
        <div
          className="details-table-row-section"
          style={{ width: `${calculateWidth}` }}
        >
          {thirdCol}
        </div>
      )}
    </div>
  );
};

export const PersonWithArrayTableRow: FC<{
  firstCol: string;
  initials?: string[];
  images?: string[];
}> = ({ firstCol, initials, images }) => {
  return (
    <div className="details-table-row">
      <div className="details-table-row-section" style={{ width: "50%" }}>
        {firstCol}
      </div>
      {initials && (
        <div className="details-table-row-section" style={{ width: "50%" }}>
          <InitialsArray fullnames={initials} />
        </div>
      )}
      {images && (
        <div className="details-table-row-section" style={{ width: "50%" }}>
          <ImageArray images={images} />
        </div>
      )}
    </div>
  );
};

export const OverviewRow: FC<{
  title: string;
  value: string | number;
}> = ({ title, value }) => {
  return (
    <div className="overview-row">
      <p className="overview-row-header">{title}</p>
      <p className="overview-row-data">{value}</p>
    </div>
  );
};
