import { createContext, useContext } from "react";
import { axiosInstance } from "../api/http";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { AuthContext } from "./AuthContext";

const AxiosContext = createContext({});

const { Provider } = AxiosContext;

const AxiosProvider = ({ children }: any) => {
  const ctx = useContext(AuthContext);
  const token = ctx.authState.token;
  axiosInstance.interceptors.request.use(
    (config: any) => {
      if (config.headers && !config.headers?.Authorization) {
        config.headers = {
          Authorization:
            config.url === "/token/refresh" ? "" : `Bearer ${token}`,
        };
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  createAuthRefreshInterceptor(axiosInstance, ctx.refreshToken, {
    statusCodes: [401],
  });
  return (
    <Provider value={{ axiosInstance: axiosInstance }}>{children}</Provider>
  );
};

export { AxiosContext, AxiosProvider };
