import SettingsMenu from "../../components/SettingsMenu/SettingsMenu";
import { FC, useEffect, useState } from "react";
import { TEACHERS } from "../../common/constants/routes.constants";
import {
  DetailsTableHeader,
  OverviewRow,
  PersonWithArrayTableRow,
} from "../../components/DetailsTableComponents/DetailsTableComponents";
import DetailsPageHeader from "../../components/DetailsPageHeader/DetailsPageHeader";
import TeacherForm from "../../components/TeacherForm/TeacherForm";
import { ReturnSection } from "../../components/FormActions/FormActions";
import { ITeacherDetails } from "../../common/interfaces/teacher.interface";
import { useParams } from "react-router";
import { getTeacherDetails } from "../../api/teachers.api";
import Loader from "../../components/Loader/Loader";
import { mapGenderValue } from "../../common/utils/helpers";

const TeacherDetailsPage: FC<{}> = () => {
  const [teacher, setTeacher] = useState<ITeacherDetails>();
  const [editing, toggleEditing] = useState<boolean>(false);

  const { teacherId } = useParams();

  useEffect(() => {
    if (!editing) fetchData();
  }, [editing]);

  const handleToggleEdit = () => {
    toggleEditing(!editing);
  };

  const fetchData = async () => {
    try {
      const teacherIdNumber = Number(teacherId);
      const response = await getTeacherDetails(teacherIdNumber);
      setTeacher(response);
    } catch (error: any) {}
  };

  return (
    <div className="padding-wrapper">
      <SettingsMenu />
      {teacher ? (
        <>
          {!editing ? (
            <>
              <DetailsPageHeader
                title={teacher.name + " " + teacher.surname}
                initials
                subtext={teacher.email}
                backRoute={TEACHERS}
                backTitle="Teachers"
                handleState={handleToggleEdit}
              />
              <div className="details-page-layout">
                <div className="details-page-section" style={{ width: "30%" }}>
                  <div className="details-page-section-header">
                    Basic Details
                  </div>
                  <OverviewRow title="First Name" value={teacher.name} />
                  <OverviewRow title="Last Name" value={teacher.surname} />
                  <OverviewRow title="Email" value={teacher.email} />
                  <OverviewRow
                    title="Gender"
                    value={mapGenderValue(teacher.gender)}
                  />
                  <OverviewRow title="Address" value={teacher.address} />
                  <OverviewRow title="Phone Number" value={teacher.phone} />
                </div>
                <div className="details-page-section">
                  <div className="details-page-section-header">Groups</div>
                  <DetailsTableHeader titles={["NAME", "OTHER TEACHERS"]} />
                  {teacher.groups.map((item, index) => (
                    <PersonWithArrayTableRow
                      firstCol={item.groupName}
                      initials={item.otherTeachers.map(
                        (item) => item.oTeacherFullName
                      )}
                      key={index}
                    />
                  ))}
                </div>
              </div>
            </>
          ) : (
            <>
              <ReturnSection handleToggleEdit={handleToggleEdit} />
              <TeacherForm
                edit
                handleToggleEdit={handleToggleEdit}
                firstName={teacher.name}
                lastName={teacher.surname}
                address={teacher.address}
                email={teacher.email}
                gender={teacher.gender}
                phNumber={teacher.phone}
                teacherId={Number(teacherId)}
                groups={teacher.groups}
              />
            </>
          )}
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default TeacherDetailsPage;
