import { CreateOrUpdateParentDto } from "../common/dtos/parents.dto";
import {
  IPaginatedParentTable,
  IParentDetails,
} from "../common/interfaces/parent.interfaces";
import { ADMIN, PARENTS } from "./api.routes";
import { deleteRequest, getRequest, postRequest } from "./http";

export function getAllParents(
  queryParams?: string
): Promise<IPaginatedParentTable> {
  return getRequest(`${ADMIN}/${PARENTS}${queryParams ? queryParams : ""}`);
}

export function getParentDetails(parentId: number): Promise<IParentDetails> {
  return getRequest(`${ADMIN}/${PARENTS}/${parentId}`);
}

export function deleteParent(parentId: number): Promise<void> {
  return deleteRequest(`${ADMIN}/${PARENTS}/${parentId}`);
}

export function createOrUpdateParent(data: CreateOrUpdateParentDto) {
  return postRequest(`${ADMIN}/${PARENTS}`, data);
}
