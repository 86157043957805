import SettingsMenu from "../../components/SettingsMenu/SettingsMenu";
import { FC, useEffect, useState } from "react";
import "./ParentDetailsPage.scss";
import { PARENTS } from "../../common/constants/routes.constants";
import {
  DetailsTableHeader,
  MultiUseTableRow,
  OverviewRow,
} from "../../components/DetailsTableComponents/DetailsTableComponents";
import DetailsPageHeader from "../../components/DetailsPageHeader/DetailsPageHeader";
import ParentForm from "../../components/ParentForm/ParentForm";
import { ReturnSection } from "../../components/FormActions/FormActions";
import Loader from "../../components/Loader/Loader";
import { useParams } from "react-router";
import { getParentDetails } from "../../api/parents.api";
import { IParentDetails } from "../../common/interfaces/parent.interfaces";
import {
  capitalizeFirstLetter,
  formatDateString,
  mapGenderValue,
} from "../../common/utils/helpers";

const ParentDetailsPage: FC<{}> = () => {
  const [parent, setParent] = useState<IParentDetails>();
  const [editing, toggleEditing] = useState<boolean>(false);

  const { parentId } = useParams();

  useEffect(() => {
    if (!editing) fetchData();
  }, [editing]);

  const handleToggleEdit = () => {
    toggleEditing(!editing);
  };

  const fetchData = async () => {
    try {
      const parentIdNumber = Number(parentId);
      const response = await getParentDetails(parentIdNumber);
      setParent(response);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  return (
    <div className="padding-wrapper">
      <SettingsMenu />
      {parent ? (
        <>
          {!editing ? (
            <>
              <DetailsPageHeader
                title={parent.name + " " + parent.surname}
                subtext={parent.email}
                initials
                backRoute={PARENTS}
                backTitle="Parents"
                handleState={handleToggleEdit}
              />
              <div className="details-page-layout">
                <div className="details-page-section" style={{ width: "30%" }}>
                  <div className="details-page-section-header">
                    Basic Details
                  </div>
                  <OverviewRow title="First Name" value={parent.name} />
                  <OverviewRow title="Last Name" value={parent.surname} />
                  <OverviewRow title="Email" value={parent.email} />
                  <OverviewRow
                    title="Date of Birth"
                    value={formatDateString(parent.dateOfBirth)}
                  />
                  <OverviewRow
                    title="Gender"
                    value={mapGenderValue(parent.gender)}
                  />
                  <OverviewRow title="Address" value={parent.address} />
                  <OverviewRow title="Phone Number" value={parent.phone} />
                </div>
                <div className="details-page-section">
                  <div className="details-page-section-header">Children</div>
                  <DetailsTableHeader titles={["NAME", "GROUP", "RELATION"]} />
                  {parent.children.map((child: any) => (
                    <MultiUseTableRow
                      key={child.childId}
                      secondCol={child.group.groupName}
                      firstCol={child.firstName + " " + child.lastName}
                      thirdCol={
                        (child.childRelation
                          ? capitalizeFirstLetter(child.childRelation)
                          : "Parent") +
                        (child.isPrimaryCarer ? " (Primary Carer)" : "")
                      }
                      includeImg
                      imageUrl={child.avatarURL}
                    />
                  ))}
                </div>
              </div>
            </>
          ) : (
            <>
              <ReturnSection handleToggleEdit={handleToggleEdit} />
              <ParentForm
                edit
                handleToggleEdit={handleToggleEdit}
                parentId={Number(parentId)}
                firstName={parent.name}
                lastName={parent.surname}
                email={parent.email}
                address={parent.address}
                phNumber={parent.phone}
                children={parent.children}
                gender={parent.gender}
                date={parent.dateOfBirth}
              />
            </>
          )}
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default ParentDetailsPage;
