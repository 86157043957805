import { Oval } from "react-loader-spinner";
import "./Loader.scss";

const Loader = () => {
  return (
    <div className={"oval-default"}>
      <Oval
        height={50}
        width={50}
        color="#111726"
        wrapperClass="oval"
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#9DA2AF"
        strokeWidth={5}
        strokeWidthSecondary={5}
      />
    </div>
  );
};
export default Loader;
