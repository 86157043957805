import { DataGrid, GridColDef } from "@mui/x-data-grid";
import SettingsMenu from "../../components/SettingsMenu/SettingsMenu";
import TitleArea from "../../components/Layout/TitleArea/TitleArea";
import SearchBar from "../../components/SearchBar/SearchBar";
import { useNavigate } from "react-router";
import { PARENT_DETAILS } from "../../common/constants/routes.constants";
import { useEffect, useState } from "react";
import { getAllParents } from "../../api/parents.api";
import ImageArray from "../../components/Tables/ImageArray";
import Loader from "../../components/Loader/Loader";
import {
  findParentsRelationship,
  toggleSort,
} from "../../common/utils/helpers";
import { ITEMS_PER_PAGE } from "../../common/constants/common.constants";
import { ITableParent } from "../../common/interfaces/parent.interfaces";

export const columns: GridColDef[] = [
  {
    field: "fullName",
    headerName: "NAME",
    headerClassName: "table-header",
    flex: 20,
    renderCell: (params) => {
      return <span>{params.row.name + " " + params.row.surname}</span>;
    },
  },
  {
    field: "children",
    headerName: "CHILD",
    headerClassName: "table-header",
    sortable: false,
    flex: 15,
    renderCell: (params) => {
      return (
        <div className="tablecell-image-array">
          <ImageArray
            images={params.row.children.map((child: any) => child.avatarURL)}
          />
        </div>
      );
    },
  },
  {
    field: "childRelation",
    headerName: "RELATIONSHIP",
    headerClassName: "table-header",
    sortable: false,
    flex: 10,
    renderCell: (params) => {
      return <span>{findParentsRelationship(params.row.children)}</span>;
    },
  },
  {
    field: "phone",
    headerName: "PHONE NUMBER",
    headerClassName: "table-header",
    sortable: false,
    flex: 10,
  },
  {
    field: "email",
    headerName: "EMAIL",
    headerClassName: "table-header",
    sortable: false,
    flex: 15,
  },
];

const ParentsPage = () => {
  const [parents, setParents] = useState<ITableParent[]>();
  const [page, setPage] = useState(1);
  const [sortOrder, setSortOrder] = useState("");
  const [filter, setFilter] = useState("");
  const [queryParam, setQueryParam] = useState("");

  const [totalCount, setTotalCount] = useState<number>();

  const navigate = useNavigate();

  useEffect(() => {
    if (queryParam) fetchData();
  }, [queryParam]);

  useEffect(() => {
    setQueryParam(
      `?page=${page}&paginationItemsPerPage=${ITEMS_PER_PAGE}&filter=${filter}&sortBy=name&sortOrder=${sortOrder}`
    );
  }, [page, sortOrder, filter]);

  const fetchData = async () => {
    try {
      const response = await getAllParents(queryParam);
      setParents(response.data);
      setTotalCount(response.totalCount);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const handleRowClick = (params: any) => {
    navigate(`${PARENT_DETAILS}/${params.id}`);
  };

  const handlePageChange = (e: any) => {
    setPage(e.page + 1);
  };

  const handleSortChange = () => {
    setSortOrder(toggleSort(sortOrder));
  };

  return (
    <div className="padding-wrapper">
      <SettingsMenu />
      <TitleArea />
      {parents ? (
        <>
          <SearchBar setSearchValue={(value) => setFilter(value)} />
          <DataGrid
            onRowClick={(params) => handleRowClick(params)}
            getRowId={(row) => row.parentId}
            rows={parents}
            disableColumnMenu
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            rowCount={totalCount}
            sx={{ border: 0 }}
            onPaginationModelChange={(e: any) => handlePageChange(e)}
            onSortModelChange={(e: any) => handleSortChange}
            sortingMode="server"
            paginationMode="server"
            sortingOrder={["asc", "desc"]}
            disableColumnSelector={true}
            localeText={{ noRowsLabel: "No data available" }}
          />
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default ParentsPage;
