import React, { useState } from "react";
import "../Tables/Tables.scss";
import { getInitials } from "../../common/utils/helpers";

const InitialsArray: React.FC<{ fullnames: string[] }> = ({ fullnames }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index: any) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };
  return (
    <div className="circle-array-wrapper">
      {fullnames.slice(0, 4).map((item, index) => (
        <>
          <div
            className="additional-number-container"
            key={index}
            style={{ zIndex: 4 - index }}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
          >
            {getInitials(item)}
            <div
              className={`initials-tooltip ${
                hoveredIndex === index ? "hovered-class" : ""
              }`}
            >
              {item}
            </div>
          </div>
        </>
      ))}
      {fullnames.length > 4 && (
        <div className="additional-number-container">{`+${
          fullnames.length - 4
        }`}</div>
      )}
    </div>
  );
};

export default InitialsArray;
