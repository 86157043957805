import { Button, Stack } from "@mui/material";
import "./TitleArea.scss";
import AddIcon from "@mui/icons-material/Add";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const PAGE_TITLES: Record<string, string> = {
  "/parents": "Parents",
  "/groups": "Groups",
  "/teachers": "Teachers",
  "/children": "Children",
};

const NAVIGATE_TO: Record<string, string> = {
  "/parents": "/parent-form",
  "/groups": "/group-form",
  "/teachers": "/teacher-form",
  "/children": "/child-form",
};

const TitleArea = () => {
  const [pageTitle, setPageTitle] = useState<string>("...");
  const [navigateTo, setNavigateTo] = useState<string>("");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const currentPage = PAGE_TITLES[location.pathname];
    setPageTitle(currentPage || "Veerny");
    setNavigateTo(NAVIGATE_TO[location.pathname]);
  }, [location.pathname]);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      id="title-area"
    >
      <h1>{pageTitle}</h1>
      <Button
        startIcon={<AddIcon />}
        className="main-button"
        variant="contained"
        onClick={() => {
          navigate(navigateTo);
        }}
      >
        Add New
      </Button>
    </Stack>
  );
};

export default TitleArea;
