import { MenuItem, TextField } from "@mui/material";
import React, { ChangeEvent } from "react";
import GroupColorCell from "../Tables/GroupColorCell";
import { COLOR_LIST } from "../../common/constants/common.constants";
import { mapColorsToHex } from "../../common/utils/helpers";

const GroupBasicDetails: React.FC<{
  nameRef: React.MutableRefObject<HTMLInputElement | undefined>;
  handleNameChange: (
    event: ChangeEvent<HTMLInputElement>,
    customRef: any
  ) => void;
  setGroupColors: React.Dispatch<React.SetStateAction<string>>;
  groupColor: string;
  errors: string[];
}> = ({ groupColor, nameRef, setGroupColors, handleNameChange, errors }) => {
  return (
    <div className="section-container">
      <h3>Basic Details</h3>
      <div className="input-section">
        <div>
          <TextField
            label="Group name *"
            inputRef={nameRef}
            size="medium"
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              handleNameChange(event, nameRef)
            }
            sx={{ width: "100%" }}
          />
          {errors.includes("groupName") && (
            <p className="error-text">Group name can not be empty</p>
          )}
        </div>
        <div>
          <TextField
            label="Choose a color"
            select
            className="color-dropdown"
            value={groupColor}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setGroupColors(mapColorsToHex(event.target.value as string))
            }
            fullWidth
          >
            {COLOR_LIST.map((color, index) => (
              <MenuItem value={color} key={index}>
                <GroupColorCell color={color} hex />
              </MenuItem>
            ))}
          </TextField>
          {errors.includes("groupColors") && (
            <p className="error-text">Please select a color</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default GroupBasicDetails;
