import {
  useState,
  useMemo,
  ChangeEvent,
  KeyboardEvent,
  FC,
  useEffect,
} from "react";
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox as MuiCheckbox,
  Select,
  InputLabel,
  ListSubheader,
  TextField,
  InputAdornment,
  IconButton,
  OutlinedInput,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { ITeacherFormGroups } from "../../common/interfaces/teacher.interface";

const containsText = (text: string, searchText: string) =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

const CustomSelectDropdown: FC<{
  chosenOptions: ITeacherFormGroups[];
  availableOptions: ITeacherFormGroups[];
  handleGroupsChange: (groups: ITeacherFormGroups[]) => void;
}> = ({ chosenOptions, availableOptions, handleGroupsChange }) => {
  const [selectedOptions, setSelectedOptions] = useState<ITeacherFormGroups[]>(
    []
  );
  const [searchText, setSearchText] = useState<string>("");

  useEffect(() => {
    setSelectedOptions([...chosenOptions]);
  }, [chosenOptions]);

  const filteredOptions = useMemo(
    () =>
      availableOptions.filter((option) =>
        containsText(option.groupName, searchText)
      ),
    [searchText, availableOptions]
  );

  const handleSearchTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleSearchTextClear = () => {
    setSearchText("");
  };

  const handleTextFieldKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== "Escape") {
      // Prevents autoselecting item while typing (default Select behaviour)
      e.stopPropagation();
    }
  };

  const handleRemoveGroup = (removedOption: number) => {
    const helperArray = selectedOptions.filter(
      (option) => option.id !== removedOption
    );

    setSelectedOptions(helperArray);
    handleGroupsChange(helperArray);
  };

  const handleOptionClick = (option: ITeacherFormGroups) => {
    const index = selectedOptions.findIndex((obj) => obj.id === option.id);

    let newArray = [];

    if (index === -1) {
      newArray = [
        ...selectedOptions,
        { id: option.id, groupName: option.groupName },
      ];
    } else {
      const helperArray = selectedOptions.filter((obj) => obj.id !== option.id);
      newArray = [...helperArray];
    }

    setSelectedOptions(newArray);
    handleGroupsChange(newArray);
  };

  return (
    <>
      <FormControl>
        <InputLabel>Groups</InputLabel>
        <Select
          MenuProps={{ autoFocus: false }}
          labelId="search-select-label"
          id="search-select"
          multiple
          className="search-select-list"
          value={selectedOptions}
          label="Options"
          onClose={handleSearchTextClear}
          input={<OutlinedInput label="Groups" />}
          renderValue={() => null}
        >
          <ListSubheader className="search-select-subheader">
            <TextField
              size="small"
              autoFocus
              placeholder="Search..."
              fullWidth
              className="select-search-input"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      style={{
                        color: "#483359",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              onChange={handleSearchTextChange}
              onKeyDown={handleTextFieldKeyDown}
            />
          </ListSubheader>
          <FormGroup>
            <div className="select-search-header">Group name</div>
            {filteredOptions.map((option, i) => (
              <FormControlLabel
                className="select-search-item"
                key={i}
                control={
                  <MuiCheckbox
                    checked={selectedOptions.some(
                      (item: ITeacherFormGroups) =>
                        item.id === filteredOptions[i]?.id
                    )}
                    style={{
                      color: "#483359",
                    }}
                    onChange={() => handleOptionClick(option)}
                  />
                }
                label={option.groupName}
              />
            ))}
          </FormGroup>
        </Select>
      </FormControl>
      <div className="selected-pills">
        {selectedOptions.map((selectedItem) => (
          <div key={selectedItem.id}>
            <span>{selectedItem.groupName}</span>
            <IconButton onClick={() => handleRemoveGroup(selectedItem.id)}>
              <CloseIcon
                style={{
                  color: "#fff",
                }}
              />
            </IconButton>
          </div>
        ))}
      </div>
    </>
  );
};

export default CustomSelectDropdown;
