import { ChangeEvent, FC, MutableRefObject } from "react";
import "react-phone-input-2/lib/material.css";
import ".././ChildForm.scss";
import { FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material";
import { MAX_IMAGE_SIZE } from "../../../common/constants/common.constants";
import { FileIcon } from "../../../svgs/constants";
import { useDropzone } from "react-dropzone";
import UploadedFile from "../ProfilePictureSection/ProfilePictureSection";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";

const ChildBasicInfoSection: FC<{
  firstNameRef: MutableRefObject<HTMLInputElement | undefined>;
  lastNameRef: MutableRefObject<HTMLInputElement | undefined>;
  addressRef: MutableRefObject<HTMLInputElement | undefined>;
  gender: string;
  setGender: React.Dispatch<React.SetStateAction<string>>;
  changeRefValue: (
    event: ChangeEvent<HTMLInputElement>,
    customRef: any,
    fieldName?: string
  ) => void;
  edit?: boolean;
  file: File | undefined;
  setFile: React.Dispatch<React.SetStateAction<File | undefined>>;
  chosenDateOfBirth: dayjs.Dayjs | null;
  onDateChange: (date: dayjs.Dayjs | null) => void;
  errors: string[];
}> = ({
  edit,
  addressRef,
  firstNameRef,
  lastNameRef,
  gender,
  setGender,
  changeRefValue,
  file,
  setFile,
  chosenDateOfBirth,
  onDateChange,
  errors,
}) => {
  const handleGenderChange = (e: ChangeEvent<HTMLInputElement>) => {
    setGender(e.target.value);
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    maxSize: MAX_IMAGE_SIZE,
    onDrop: async (acceptedFiles) => {
      setFile(acceptedFiles[0]);
    },
  });

  const removeImage = () => {
    setFile(undefined);
  };

  return (
    <div className="section-container">
      <h3>Basic Details</h3>
      <div className="input-section">
        <div>
          <TextField
            inputRef={firstNameRef}
            label="First name *"
            size="medium"
            onInput={(e: ChangeEvent<HTMLInputElement>) =>
              changeRefValue(e, firstNameRef, "name")
            }
            sx={{ width: "100%" }}
          />
          {errors.includes("name") && (
            <p className="error-text">Name can not be empty</p>
          )}
        </div>
        <div>
          <TextField
            inputRef={lastNameRef}
            label="Last name *"
            size="medium"
            onInput={(e: ChangeEvent<HTMLInputElement>) =>
              changeRefValue(e, lastNameRef, "surname")
            }
            sx={{ width: "100%" }}
          />
          {errors.includes("surname") && (
            <p className="error-text">Surname can not be empty</p>
          )}
        </div>
        <div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Date of Birth *"
              sx={{ width: "100%" }}
              value={chosenDateOfBirth}
              onChange={onDateChange}
            />
          </LocalizationProvider>
          {errors.includes("birthDate") && (
            <p className="error-text">Date of birth cannot be empty</p>
          )}
        </div>
        <div>
          <TextField
            inputRef={addressRef}
            label="Address *"
            size="medium"
            onInput={(e: ChangeEvent<HTMLInputElement>) =>
              changeRefValue(e, addressRef, "address")
            }
            sx={{ width: "100%" }}
          />
          {errors.includes("address") && (
            <p className="error-text">Address cannot be empty</p>
          )}
        </div>
        <div className="radio-wrapper">
          <h4>Gender</h4>
          <RadioGroup
            aria-label="gender"
            name="gender"
            value={gender}
            onChange={handleGenderChange}
            row
          >
            <FormControlLabel
              value="M"
              control={<Radio style={{ color: "#3f3273" }} />}
              label="Male"
            />
            <FormControlLabel
              value="F"
              control={<Radio style={{ color: "#3f3273" }} />}
              label="Female"
            />
          </RadioGroup>
          {errors.includes("gender") && (
            <p className="error-text">Gender must be selected</p>
          )}
        </div>
        <div>
          <h4>Profile Picture</h4>
          <div {...getRootProps()} className="upload-image-container">
            <FileIcon />
            <p className="upload-main-text">
              <input {...getInputProps()} />
              <span onClick={open}>Click to upload</span> or drag and drop
            </p>
            <p className="upload-side-text">SVG, PNG, JPG (max. 3MB)</p>
          </div>
          {file !== undefined && (
            <UploadedFile
              name={file.name ?? "Current logo"}
              removeImage={removeImage}
            ></UploadedFile>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChildBasicInfoSection;
